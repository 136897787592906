import React from "react"
import { Stack } from "react-bootstrap"

const MediaIcon = () => {
  return (
    <Stack className='media-icon my-4' direction='horizontal' gap={4}>
      <i className='fa-brands fa-facebook' style={{cursor:"pointer"}} onClick={()=>window.open("https://www.facebook.com/profile.php?id=100088855806223&mibextid=ZbWKwL","_blank")} ></i>
      {/* <i className='fa-brands fa-twitter' style={{cursor:"pointer"}} onClick={()=>window.open("https://www.facebook.com/profile.php?id=100088855806223&mibextid=ZbWKwL","_blank")}></i> */}
      <i className='fa-brands fa-instagram' style={{cursor:"pointer"}} onClick={()=>window.open("https://instagram.com/doomoble","_blank")}></i>
      <i className='fa-solid fa-envelope' style={{cursor:"pointer"}} onClick={()=>window.open("mailto:contact@doomoble.com","_blank")}></i>
      <i className='fa-brands fa-youtube' style={{ cursor: "pointer" }} onClick={() => window.open("https://youtube.com/@doomoble692", "_blank")}></i>
      <i className='fa-brands fa-whatsapp' style={{cursor:"pointer"}} onClick={()=>window.open("https://wa.me/+13477152240","_blank")}></i>

    </Stack>
  )
}

export default MediaIcon
