import React, { useEffect, useState } from "react";
import { Route, Routes } from "react-router-dom";

import {
  HomeScreen,
  AdminDashboardScreen,
  AboutUsScreen,
  ContactUsScreen,
  LoginScreen,
  SignupScreen,
  ServicesScreen,
  AddBusinessScreen,
  ScheduleService,
  BusinessDetails,
  DashBoradScreen,
  BusinessPreview,
  AdminLoginScreen,
  ForgotScreen,
  ResetScreen,
} from "./screens";
import { Header, Footer } from "./components";
import { useDispatch, useSelector } from "react-redux";
import { getSettings } from "./actions/settingsAction.js";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import PreviewBusiness from "./components/adminDashboard/PreviewBusiness.jsx";

export default function App() {
  const [sett, setSett] = useState();
  const dispatch = useDispatch();
  const { settingsInfo } = useSelector((state) => state.settingsUp);

  //*******set up setting*******
  useEffect(() => {
    dispatch(getSettings());
  }, []);

  useEffect(() => {
    if (settingsInfo) {
      setSett(settingsInfo);
    }
  }, [settingsInfo]);
  //*******main return*******
  return (
    <div className="d-flex flex-column vh-100">
      <ToastContainer
        position="top-center"
        autoClose={3000}
        hideProgressBar
        newestOnTop={false}
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
      {sett !== undefined ? (
        <>
          <Header />
          <main>
            <Routes>
              <Route
                path="/forgot/business"
                element={<ForgotScreen type="business" />}
                exact
              />
              <Route
                path="/forgot/user"
                element={<ForgotScreen type="user" exact />}
              />
              <Route path="/reset/:id" element={<ResetScreen />} />

              <Route path="/aboutus" element={<AboutUsScreen />} />
              <Route path="/contact" element={<ContactUsScreen />} />
              <Route path="/services" element={<ServicesScreen />} />
              <Route path="/search" element={<ServicesScreen />} />
              <Route path="/signin/user" element={<LoginScreen />} />
              <Route path="/signin/business" element={<LoginScreen />} />
              <Route path="/signup/user" element={<SignupScreen />} />
              <Route
                path="/signup/business"
                element={<AddBusinessScreen googleKey={sett[0].googlekey} />}
              />
              <Route
                path="/preview-business"
                element={<PreviewBusiness googleKey={sett[0].googlekey} />}
              />
              <Route
                path="/admindashboard"
                element={<AdminDashboardScreen googleKey={sett[0].googlekey} />}
              />
              <Route path="/admin" element={<AdminLoginScreen />} exact />
              <Route
                path="/addbusiness"
                element={<AddBusinessScreen googleKey={sett[0].googlekey} />}
              />
              <Route path="/dashboard/business" element={<DashBoradScreen />} />
              <Route path="/dashboard/user" element={<DashBoradScreen />} />
              <Route
                path="/dashboard/manage/user"
                element={<DashBoradScreen />}
              />
              <Route
                path="/dashboard/calendar/user"
                element={<DashBoradScreen />}
              />
              <Route
                path="/dashboard/account/user"
                element={<DashBoradScreen />}
              />
              <Route
                path="/addbusiness/:service"
                element={<AddBusinessScreen googleKey={sett[0].googlekey} />}
                exact
              />
              <Route path="/scheduleservice" element={<ScheduleService />} />
              <Route
                path="/search/:category"
                element={<ServicesScreen />}
                exact
              />
              <Route
                path="/search/:keyword"
                element={<ServicesScreen />}
                exact
              />
              <Route
                path="/search/:keyword/:category"
                element={<ServicesScreen />}
                exact
              />
              <Route
                path="/service/:category/:id"
                element={<BusinessDetails googleKey={sett[0].googlekey} />}
                exact
              />
              <Route
                path="/preview/:category/:id"
                element={<BusinessPreview googleKey={sett[0].googlekey} />}
                exact
              />

              <Route path="/" element={<HomeScreen />} exact />
            </Routes>
          </main>
          <Footer />
        </>
      ) : (
        ""
      )}
    </div>
  );
}
