import React, { useState, useEffect, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { Stack, Container } from "react-bootstrap";
import Geocode from "react-geocode";
import AddBusinessView from "../components/addBusinessScreen/view/AddBusinessView";
import { logout, businessRegister, sendEmail } from "../actions";
import { welcomeBusinessMessage } from "../assets/email/welcomeBusinessEmail";

import {
  onNameChange,
  onEmailChange,
  onPasswordChange,
  onConfPasswordChange,
  onPhoneChange,
  onBusinessNameChange,
  onCategoryChange,
  onOtherCategoryChange,
  onAddressChange,
  onAptChange,
  onDescriptionChange,
  createAccountHandler,
  addGeoCode,
  getBusiness,
} from "../components/addBusinessScreen/model/AddBusinessFormModel";
import { toast } from "react-toastify";
import { getSettings } from "../actions/settingsAction";

const AddBusinessScreen = ({ googleKey }) => {
  const [inputError, setInputError] = useState();
  const newBusiness = useSelector((state) => state.businessRegister);
  const { settingsInfo } = useSelector((state) => state.settingsUp);
  const { error, businessInfo } = useSelector(
    (state) => state.businessRegister
  );

  const categoryNameList = useMemo(() => {
    const mainServices =
      settingsInfo?.[0]?.mainServices?.map((item) =>
        item?.name?.trim()?.toLowerCase()
      ) || [];
    const usersServices =
      settingsInfo?.[0]?.usersServices?.map((item) =>
        item?.name?.trim()?.toLowerCase()
      ) || [];
    return [...mainServices, ...usersServices];
  }, [settingsInfo]);
  /**
   * Geocode set up
   */

  useEffect(() => {
    dispatch(getSettings());
  }, []);
  useEffect(() => {
    if (googleKey) {
      Geocode.setApiKey(googleKey);
      addGeoCode(googleKey);
    }
  }, [googleKey]);
  /**
   * Check if business log in
   */
  // const businessInfo = localStorage.getItem("businessInfo")

  useEffect(() => {
    if (error) {
      toast.error(error);
    }
  }, [error]);
  //slicer for business info
  //const form
  // const [form, setForm] = useState({ category: "spa" })
  // const [errors, setErrors] = useState({})

  // ***********

  // const [address, setAddress] = useState()
  // const [addOther, showAddOther] = useState(false)

  //work with browser url
  const history = useNavigate();
  const dispatch = useDispatch();

  // const sentInformation = (newName) => {
  //   dispatch(logout())
  //   dispatch(
  //     businessRegister(
  //       form.name,
  //       form.email,
  //       form.password,
  //       form.phonenumber,
  //       form.businessname,
  //       form.description,
  //       form.category,
  //       form.address,
  //       form.lat,
  //       form.lng,
  //       form.apt,
  //       newName
  //     )
  //   )
  // }

  //submit form function
  // const submitHandler = (e) => {
  //   e.preventDefault()
  //   const formErrors = validateForm()
  //   getCoordinates()

  //   if (Object.keys(formErrors).length > 0) {
  //     setErrors(formErrors)
  //   } else {
  //     if (form.category === "other") {
  //       let capCat = form.othercategory.toLowerCase().replace(/\s/g, "")
  //       const newName = form.othercategory
  //       form.category = capCat
  //       sentInformation(newName)
  //     }
  //   }
  // }

  const onCreateAccountHandler = () => {
    const isValid = createAccountHandler(categoryNameList);

    if (isValid) {
      setInputError(isValid);
    } else {
      setInputError(undefined);
      const newBusiness = getBusiness();

      const duplicateBusiness = JSON.parse(JSON.stringify(newBusiness));
      if (newBusiness.category === "custom-other") {
        duplicateBusiness.category = newBusiness?.categoryName
          ?.replaceAll(" ", "")
          ?.toLowerCase();
      }

      dispatch(logout());
      dispatch(
        businessRegister(duplicateBusiness, function () {
          const messageText = welcomeBusinessMessage();
          dispatch(
            sendEmail({
              name: duplicateBusiness.businessName,
              email: "contact@doomoble.com",
              message: messageText,
              to: duplicateBusiness.email,
              subject: `DooMoble`,
            })
          );
          toast.success("Welcome to DooMoble");
          history("/dashboard/business");
        })
      );
    }
  };

  // const errorOther
  //main return
  return (
    <Stack direction="vertical" className="bg-white">
      <Container>
        <AddBusinessView
          onNameChange={onNameChange}
          onEmailChange={onEmailChange}
          onPasswordChange={onPasswordChange}
          onConfPasswordChange={onConfPasswordChange}
          onPhoneChange={onPhoneChange}
          onBusinessNameChange={onBusinessNameChange}
          onCategoryChange={onCategoryChange}
          onOtherCategoryChange={onOtherCategoryChange}
          onAddressChange={onAddressChange}
          onAptChange={onAptChange}
          onDescriptionChange={onDescriptionChange}
          onCreateAccountHandler={onCreateAccountHandler}
          nameError={inputError !== undefined ? inputError.nameError : false}
          emailError={inputError !== undefined ? inputError.emailError : false}
          passwordError={
            inputError !== undefined ? inputError.passwordError : false
          }
          confPasswordError={
            inputError !== undefined ? inputError.confPasswordError : false
          }
          phoneError={inputError !== undefined ? inputError.phoneError : false}
          businessNameError={
            inputError !== undefined ? inputError.businessNameError : false
          }
          categoryError={
            inputError !== undefined ? inputError.categoryError : false
          }
          isOtherCategory={false}
          otherCategoryError={
            inputError !== undefined ? inputError.otherCategoryError : false
          }
          addressError={
            inputError !== undefined ? inputError.addressError : false
          }
          descriptionError={
            inputError !== undefined ? inputError.descriptionError : false
          }
        />
      </Container>
    </Stack>
  );
};

export default AddBusinessScreen;
