import React from 'react'
import { LinkContainer } from 'react-router-bootstrap'
import { Row, Col, Button } from 'react-bootstrap'
import { SearchBox } from '../index'

const TopHomeScreen = ({ searchFilter, isUser }) => {
  return (
    <Row className='m-0 p-0d-flex justify-content-center top-main'>
      <Col xl={8}>
        <Row>
          <Col>
            <h4 className='w-100% text-center  mt-5 text-orange'>DooMoble</h4>
          </Col>
        </Row>
        <Row>
          <Col>
            <h6 className='text-white text-center'>
              We bring the business to you!
            </h6>
          </Col>
        </Row>
        <Row>
          <Col>
            <SearchBox searchFilter={searchFilter} />
          </Col>
        </Row>
        <Row className='d-flex justify-content-center w-100 m-0 p-0'>
          <Col xl={7} lg={5} md={4}>
            <Row className='d-flex justify-content-center w-100 gap-3 m-0 p-0'>
              <Col xl={4} lg={7} className='text-center '>
                <LinkContainer
                  to={{
                    search: 'category=allbusinesses',
                    pathname: !isUser ? '/signin/user' : '/search',
                  }}
                >
                  <Button
                    variant='dark'
                    size='sm'
                    className='top-search-button w-100'
                  >
                    Schedule a Service
                  </Button>
                </LinkContainer>
              </Col>
              <Col xl={4} lg={7} className='text-center mb-5'>
                <LinkContainer to='/addbusiness'>
                  <Button
                    variant='outline-dark '
                    size='sm'
                    className='top-search-button-two w-100'
                  >
                    Add A Business
                  </Button>
                </LinkContainer>
              </Col>
            </Row>
          </Col>
        </Row>
      </Col>
    </Row>
  )
}

export default TopHomeScreen
