import React from 'react'
import { LinkContainer } from 'react-router-bootstrap'
import { Row, Col } from 'react-bootstrap'
import { BlackButton } from '../UI'

const AboutUsSearch = () => {
  return (
    <Row className='bg-orange d-flex justify-content-center m-0 p-0'>
      <Col xl={8} md={12} xs={12} className='p-3 my-5'>
        <Row className='w-100 d-flex align-items-center'>
          <Col lg={7} md={6} sm={12}>
            <h2 className='text-black'>
              <span className='text-white'>Choose a </span>mobile business
              <span className='text-white'> and </span> schedule a service
              <span className='text-white'> today!</span>
            </h2>
          </Col>
          <Col
            lg={5}
            md={6}
            sm={12}
            className='d-flex justify-content-xl-end justify-content-md-end justify-content-center'
          >
            <BlackButton link='/search' text='Search Businesses' />
          </Col>
        </Row>
      </Col>
    </Row>
  )
}

export default AboutUsSearch
