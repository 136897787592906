import React from "react"
import { Button } from "react-bootstrap"

const SideMenuButton = ({ type, typeOfPage, icon, clickHandler, text }) => {
  return (
    <Button
      active={typeOfPage === type}
      variant='light'
      className='menu-btn text-start'
      onClick={() => clickHandler(type)}
    >
      <i className={icon}></i> {text}
    </Button>
  )
}

export default SideMenuButton
