import React from "react"
import { Row, Col } from "react-bootstrap"
import ButtonShadow from "../../../../UI/ButtonShadow"

const HeaderTitle = ({
  setIsEditApp,
  isEditApp,
  status,
  accAppt,
  cancelAppt,
  complAppt,
}) => {
  return (
    <Row className='mx-1 border'>
      {isEditApp && (
        <Col xl={4} md={4} xs={12} className='my-2'>
          <ButtonShadow
            text='Close'
            color='red'
            icon='bi bi-x-circle ms-2'
            handleOnClick={() => setIsEditApp(false)}
          />
        </Col>
      )}
      {status === "Pending" && !isEditApp && (
        <Col xl={4} md={4} xs={12} className='float-end'>
          <ButtonShadow
            text='Manage Appointment'
            color='#0047AB'
            icon='bi bi-alarm-fill ms-2'
            handleOnClick={() => setIsEditApp(true)}
          />
        </Col>
      )}
      {status === "Accepted" && (
        <>
          <Col xl={4} md={4} xs={12} className='my-2'></Col>
          <Col xl={4} md={4} xs={12} className='my-2'>
            <ButtonShadow
              text='Mark as Completed'
              color='white'
              icon='bi bi-bookmark-check ms-2'
              bgcolor='green'
              handleOnClick={complAppt}
            />
          </Col>
        </>
      )}
      {status === "Pending" && isEditApp && (
      <>
        <Col xl={4} md={4} xs={12} className='my-2'>
          <ButtonShadow
            text='Accept Appointment'
            color='white'
            bgcolor='green'
            icon='bi bi-check-circle-fill ms-2'
            handleOnClick={accAppt}
          />
        </Col>
        <Col xl={4} md={4} xs={12} className='my-2'>
          <ButtonShadow
            text='Cancel Appointment'
            color='white'
            icon='bi bi-check-circle-fill ms-2'
            bgcolor='red'
            handleOnClick={cancelAppt}
          />
        </Col>
      </>
      )}
    </Row>
  )
}

export default HeaderTitle
