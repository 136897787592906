import { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { Modal } from 'react-bootstrap'
import { bdstr } from '../strings'

import RatingView from './components/view/RatingView'
import RatingDoo from '../userDashborad/components/model/RatingDoo'
import {
  UserInfoUD,
  BusinessInfoUD,
  LocationUD,
  ServicesUD,
  SummaryUD,
  DataSelectUD,
} from '../userDashborad/components/modalAppointment'
import { Loader, ButtonShadow } from '../../components'

const ModalAppointment = ({
  showModal,
  handleCloseModal,
  userInfo,
  getBusinessInfo,
  appointmentInfo,
  reschAppHandler,
  cancelAppHandler,
}) => {
  // const [loading, setLoading] = useState(true)
  const [isEditApp, setIsEditApp] = useState(false)

  const [businessInfo, setBusinessInfo] = useState(getBusinessInfo)
  // useEffect(() => {
  //   if (getBusinessInfo !== undefined) {
  //     setBusinessInfo(getBusinessInfo)
  //   }
  // }, [getBusinessInfo])

  useEffect(() => {}, [])

  const [location, setLocation] = useState(getBusinessInfo.locations)
  const [apptLocation, setApptLocation] = useState(appointmentInfo.location)
  const [services, setServices] = useState(
    getBusinessInfo.services.map((s) => s)
  )
  const [apptServices, setApptServices] = useState(
    appointmentInfo.services.map((s) => s)
  )
  const [apptDateTime, setApptDateTime] = useState(appointmentInfo.datetime)
  const [apptSchedule, setApptSchedule] = useState()
  /**
   * Rating
   */
  const ratingDoo = new RatingDoo(appointmentInfo.isRated)
  const [rating, setRating] = useState(ratingDoo.getRating())

  useEffect(() => {
    setRating(appointmentInfo.isRated)
  }, [appointmentInfo])

  const setRatingHandler = (rating) => {
    ratingDoo.updateRating(rating, appointmentInfo._id)
    setRating(ratingDoo.getRating())
  }

  useEffect(() => {
    const temp = getBusinessInfo.schedule.find(
      (x) => x.location.address === appointmentInfo.location.address
    )
    setApptSchedule(temp)
    //     setLoading(false)
  }, [appointmentInfo.location.address, getBusinessInfo.schedule])

  const clickLocationHandler = (loc) => {
    setApptLocation(loc)
    if (loc) {
      const temp = getBusinessInfo.schedule.find(
        (x) => x.location.address === loc.address
      )
      setApptSchedule(temp)
    }
  }
  const clickServiceHandler = (ser) => {
    if (apptServices.findIndex((s) => s._id === ser._id) > -1) {
      const temp = apptServices.filter((s) => s._id !== ser._id)
      setApptServices(temp)
    } else {
      setApptServices((old) => [...old, ser])
    }
  }

  const clickDateHandler = (date) => {
    setApptDateTime(date)
  }

  const cancelUpdateAppHandler = () => {
    setApptLocation(appointmentInfo.location)
    setApptServices(appointmentInfo.services.map((s) => s))
    const temp = getBusinessInfo.schedule.find(
      (x) => x.location.address === appointmentInfo.location.address
    )
    clickDateHandler(appointmentInfo.datetime)
    setApptSchedule(temp)
    setIsEditApp(false)
  }

  const resAppt = () => {
    const tempAppt = appointmentInfo
    tempAppt.location = {
      address: apptLocation.address,
      latitude: apptLocation.latitude,
      longitude: apptLocation.longitude,
    }
    tempAppt.services = apptServices
    tempAppt.datetime = apptDateTime.toISOString()
    reschAppHandler(tempAppt, businessInfo)
    setIsEditApp(false)
  }

  const cnclAppt = () => {
    cancelAppHandler(appointmentInfo)
    setIsEditApp(false)
  }

  return (
    <Modal
      show={showModal}
      onHide={handleCloseModal}
      backdrop='static'
      centered
      size='xl'
      scrollable='true'
      className=' text-black vh-100'
    >
      {/* *******Header******* */}
      <Modal.Header closeButton className='btn-close-white border-0 vh-20'>
        <Modal.Title
          as='h6'
          style={{ color: '#ffffff' }}
          className='sticky-top'
        >
          {bdstr[0]}
        </Modal.Title>
      </Modal.Header>
      {/* *******Body******* */}
      {appointmentInfo.userstatus === 'Completed' && (
        <RatingView rating={rating} setRating={setRatingHandler} />
      )}

      <hr className='border' />

      <Modal.Body className='text-black d-flex flex-column '>
        <>
          {userInfo !== undefined ? (
            <UserInfoUD avatar={userInfo.avatar.path} name={userInfo.name} />
          ) : (
            ''
          )}

          <hr className='border' />
          <BusinessInfoUD
            avatar={businessInfo.avatar.path}
            name={businessInfo.name}
            rating={businessInfo.rating}
            ratingNumber={businessInfo.ratingNumber}
          />
          <hr className='border' />
          <div className={isEditApp ? 'modal-inactive' : 'modal-active'}>
            <LocationUD
              location={location}
              clickLocationHandler={clickLocationHandler}
              apptLocation={apptLocation}
            />
          </div>
          <hr className='border' />
          <div className={isEditApp ? 'modal-inactive' : 'modal-active'}>
            <ServicesUD
              services={services}
              clickServiceHandler={clickServiceHandler}
              apptServices={apptServices}
            />
          </div>
          <hr className='border' />
          <div className={isEditApp ? 'modal-inactive' : 'modal-active'}>
            {apptSchedule !== undefined && (
              <DataSelectUD
                clickDateHandler={clickDateHandler}
                apptDateTime={apptDateTime}
                locationDOF={apptSchedule}
              />
            )}
          </div>
          <hr className='border' />
          <SummaryUD
            name={businessInfo.name}
            location={apptLocation}
            services={apptServices}
            apptDateTime={apptDateTime}
          />
        </>
      </Modal.Body>

      {/* *******Footer****** */}
      <Modal.Footer
        as='div'
        className='mt-auto d-flex justify-content-start m-2'
      >
        {appointmentInfo.userstatus === 'Pending' && !isEditApp && (
          <ButtonShadow
            text='Manage Appointment'
            color='#0047AB'
            icon='bi bi-alarm-fill ms-2'
            handleOnClick={() => setIsEditApp(true)}
          />
        )}
        {appointmentInfo.userstatus === 'Pending' && isEditApp && (
          <div className='d-flex flex-row w-100'>
            <div className='w-100'>
              <ButtonShadow
                text='Cancel Update'
                color='#c72525'
                icon='bi bi-x-lg ms-2'
                handleOnClick={cancelUpdateAppHandler}
              />
            </div>
            <div className='d-flex justify-content-end w-100'>
              <div className='me-4'>
                <ButtonShadow
                  text='Reschedule Appointment'
                  color='#008000'
                  icon='bi bi-alarm-fill ms-2'
                  handleOnClick={resAppt}
                />
              </div>
              <div>
                <ButtonShadow
                  text='Cancel Appointment'
                  color='#fff'
                  bgcolor='#c72525'
                  icon='bi bi-trash3-fill ms-2'
                  handleOnClick={cnclAppt}
                />
              </div>
            </div>
          </div>
        )}
        <div>
          <ButtonShadow
            text='Close'
            color='#fff'
            bgcolor='#c72525'
            icon='bi bi-trash3-fill ms-2'
            handleOnClick={handleCloseModal}
          />
        </div>
      </Modal.Footer>
    </Modal>
  )
}

export default ModalAppointment
