import React from "react";
import { useErrorBoundary } from "use-error-boundary";
import Card from "react-bootstrap/Card";

function ErrorBoundary({ children }) {
  const {
    ErrorBoundary: ReactErrorBoundary,
    didCatch,
    error,
  } = useErrorBoundary();

  return (
    <>
      {didCatch ? (
        <div className="vh-100 position-relative">
          <div className="position-absolute top-50 start-50 translate-middle">
            <Card style={{ maxWidth: "38rem" }}>
              <Card.Header>Error</Card.Header>
              <Card.Body>
                <p>An error has been caught: {error.message}</p>
              </Card.Body>
            </Card>
          </div>
        </div>
      ) : (
        <ReactErrorBoundary>{children}</ReactErrorBoundary>
      )}
    </>
  );
}

export default ErrorBoundary;
