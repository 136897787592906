import React from "react"
import { LinkContainer } from "react-router-bootstrap"
import { Row, Form, FormGroup, Col } from "react-bootstrap"

const LoginView = ({
  typeOfUser,
  email,
  password,
  setEmail,
  setPassword,
  errorEmail,
  errorPassword,
  submitHandler,
}) => {
  return (
    <Row className='m-0 p-0 w-100 text-muted'>
      <Col>
        <Form className='my-5  mx-auto' noValidate>
          <h6 className='text-black text-center text-capitalize mt-5'>{`Login to ${typeOfUser}  Account`}</h6>
          <FormGroup>
            <Form.Label as='p' style={{ fontSize: "0.8rem" }}>
              Email
            </Form.Label>
            <Form.Control
              type='email'
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder='doomoble@email.com'
              isInvalid={errorEmail}
            />
            <Form.Control.Feedback type='invalid'>
              {errorEmail}
            </Form.Control.Feedback>
          </FormGroup>
          <FormGroup className='mt-3'>
            <Form.Label as='p' style={{ fontSize: "0.8rem" }}>
              Password
            </Form.Label>
            <Form.Control
              type='password'
              autoComplete='true'
              placeholder='*****'
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              isInvalid={errorPassword}
            />
            <Form.Control.Feedback type='invalid'>
              {errorPassword}
            </Form.Control.Feedback>
          </FormGroup>
          <LinkContainer to={`/forgot/${typeOfUser}`}>
            <span className='ls-signup-text'>Forgot password?</span>
          </LinkContainer>
          <Row className='w-100 d-flex justify-content-end m-0 p-0'>
            <Col xs={12} md={6} xl={6}>
              <button
                type='button'
                className='black-button w-100'
                onClick={submitHandler}
              >
                Sign In
              </button>
            </Col>
          </Row>
          <div className='text-end mt-3'>
            <LinkContainer to={`/signup/${typeOfUser}`}>
              <span className='ls-signup-text'>Don't have an account?</span>
            </LinkContainer>
          </div>
        </Form>
      </Col>
    </Row>
  )
}

export default LoginView
