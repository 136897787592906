import React from 'react'
import { Button } from 'react-bootstrap'
import { LinkContainer } from 'react-router-bootstrap'
import { useSelector } from 'react-redux'
const Subscribe = () => {
  //slicer for user info
  const { userInfo } = useSelector((state) => state.userLogin)
  return (
    <div>
      {userInfo ? (
        <>
          <h6 className='my-4'>
            To enjoy all the benefits, book a service now.
          </h6>
          <LinkContainer
            to={{
              search: 'category=allbusinesses',
              pathname: '/search',
            }}
          >
            <Button
              variant='warning'
              type='button'
              className='bg-warning text-white'
            >
              Schedule an Appointment
            </Button>
          </LinkContainer>
        </>
      ) : (
        <>
          <h4 className='mb-3'>Sign Up</h4>
          <h6 className='my-4'>
            Sign up to enjoy all the benefits of service.
          </h6>
          <LinkContainer to='/signup/user'>
            <Button variant='warning' className='bg-orange' type='button'>
              Sign Up to Schedule Appointment
            </Button>
          </LinkContainer>
        </>
      )}
    </div>
  )
}
export default Subscribe
