import React from "react"
import { Col } from "react-bootstrap"
import { AccountManager, MyCalendar, ManageAppointment } from "../../index"

const MainView = ({
  typeOfPage,
  appts,
  usrInfo,
  cancelAppointment,
  updateAppointmentHandler,
}) => {
  return (
    <Col lg={9} md={7} sm={12} className='my-4 px-4'>
      {typeOfPage === "account" && <AccountManager />}
      {typeOfPage === "calendar" && <MyCalendar appts={appts} />}
      {typeOfPage === "manage" && (
        <ManageAppointment
          appts={appts}
          userInfo={usrInfo}
          cancelAppointment={cancelAppointment}
          updateAppointmentHandler={updateAppointmentHandler}
        />
      )}
    </Col>
  )
}

export default MainView
