import React from "react"
import { Row, Col } from "react-bootstrap"

const ContactUsTop = () => {
  return (
    <Row className='m-0 p-0 justify-content-md-center top-main text-center'>
      <Col>
        <h4 className='text-orange mt-5'>Contact Us</h4>
        <h6 className='text-white mb-5'>Reach out to DooMoble</h6>
      </Col>
    </Row>
  )
}

export default ContactUsTop
