import React from "react"
import { Row, Col, Form } from "react-bootstrap"
import NumberFormat from "react-number-format"

const ContentWhite = ({ submitHandler, form, errors, setField }) => {
  return (
    <Row className='d-flex justify-content-center bg-white m-0 p-0 h-100'>
      <Col xl={8} xs={12} md={10} className='my-5 mx-0'>
        <Form onSubmit={submitHandler}>
          <Row>
            <Col>
              <h5 className='text-black'>Questions?</h5>
              <h6 className='text-black'>
                Leave us a message and one of our representatives will reach
                back to you shortly!
              </h6>
            </Col>
          </Row>
          <Row>
            <Col xl={6} md={12} xs={12}>
              <Form.Group>
                <Form.Label>Name</Form.Label>
                <Form.Control
                  type='text'
                  placeholder='John Smith'
                  value={form.name}
                  onChange={(e) => setField("name", e.target.value)}
                  isInvalid={!!errors.name}
                />
                <Form.Control.Feedback type='invalid'>
                  {errors.name}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col xl={6} md={12} xs={12}>
              <Form.Group>
                <Form.Label>Phone Number</Form.Label>
                <NumberFormat
                  format='(###) ###-####'
                  mask='_'
                  placeholder='(323)445-6787'
                  customInput={Form.Control}
                  value={form.phnub}
                  isInvalid={!!errors.phnub}
                  className={!!errors.phnub ? "is-invalid" : ""}
                  onValueChange={(e) =>
                    setField("phnub", e.floatValue.toString())
                  }
                />

                <Form.Control.Feedback type='invalid'>
                  {errors.phnub}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Group>
                <Form.Label>Email</Form.Label>
                <Form.Control
                  type='email'
                  placeholder='johnsmith@email.com'
                  value={form.email}
                  onChange={(e) => setField("email", e.target.value)}
                  isInvalid={!!errors.email}
                />
                <Form.Control.Feedback type='invalid'>
                  {errors.email}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Group>
                <Form.Label>Message</Form.Label>
                <Form.Control
                  as='textarea'
                  rows={3}
                  placeholder='Hey There!'
                  value={form.message}
                  onChange={(e) => setField("message", e.target.value)}
                  isInvalid={!!errors.message}
                />
                <Form.Control.Feedback type='invalid'>
                  {errors.message}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>
          <Row className='d-flex justify-content-end mt-5'>
            <Col xl={4}>
              <button type='submit' className='black-button w-100'>
                Send
              </button>
            </Col>
          </Row>
        </Form>
      </Col>
    </Row>
  )
}

export default ContentWhite
