import React from "react"
import { Row, Col, Button } from "react-bootstrap"
import { useNavigate } from "react-router-dom"
import { DUMMY_SERVICES_LINE } from "../../assets/dummy/dummyservices"
import { SvgIcon } from "../../components"

const AddBusiness = ({ buttonClickHandler }) => {
  return (
    <Row className='d-flex justify-content-center bg-black m-0 p-0'>
      <Col xl={10} md={12} xs={12}>
        <Row className='w-100 m-0 p-0'>
          <Col>
            <h5 className='mt-4 text-center'>Add your business</h5>
          </Col>
        </Row>
        <Row className='w-100 mx-0 mb-4 p-0'>
          <Col>
            <h6 className='fw-normal text-center'>Get Listed on DooMoble</h6>
          </Col>
        </Row>
        <Row className='w-100 d-flex justify-content-center m-0 p-0 '>
          <Col xl={12} lg={12} md={12} xs={7}>
            <Row className='w-100 d-flex justify-content-center m-0 p-0'>
              {DUMMY_SERVICES_LINE.map((e) => (
                <Col
                  xl={1}
                  lg={2}
                  md={2}
                  xs={12}
                  key={e.id}
                  style={{ width: "12rem" }}
                  className='mb-4'
                >
                  <Row className='m-0 p-0   w-100'>
                    <Col className='m-0 p-0 mx-auto '>
                      <div className='addbusiness-card-img d-flex justify-content-center m-0 mx-auto'>
                        <SvgIcon
                          className='w-100'
                          stroke='white'
                          fill='white'
                          comp={e.icon.element}
                          size={e.icon.size}
                          sz='50px'
                        />
                      </div>
                    </Col>
                  </Row>
                  <Row className='d-flex justify-content-center text-center w-100 m-0 p-0'>
                    <Col className='text-center'>
                      <div>{e.name}</div>
                    </Col>
                  </Row>
                </Col>
              ))}
            </Row>
          </Col>
        </Row>
        <Row className='m-0 p-0 d-flex justify-content-center my-5'>
          <Col xl={2} xs={12} md={4}>
            <Button
              variant='outline-dark'
              size='lg'
              className='add-business-button w-100'
              onClick={buttonClickHandler}
            >
              Add A Business
            </Button>
          </Col>
        </Row>
      </Col>
    </Row>
  )
}

export default AddBusiness
