import React from "react";
import { Row, Form, Button } from "react-bootstrap";
import AddBusinessFormView from "./AddBusinessFormView";
import AddBusinessPhoneView from "./AddBusinessPhoneView";
import AddBusinessCategoryView from "./AddBusinessCategoryView";
import AddBusinessAdressView from "./AddBusinessAdressView";
import AddBusinessDescription from "./AddBusinessDescription";
import { LinkContainer } from "react-router-bootstrap";

const AddBusinessView = ({
  onNameChange,
  onEmailChange,
  onPasswordChange,
  onConfPasswordChange,
  onPhoneChange,
  onBusinessNameChange,
  onCategoryChange,
  onOtherCategoryChange,
  onAddressChange,
  onAptChange,
  onDescriptionChange,
  onCreateAccountHandler,
  nameError,
  emailError,
  passwordError,
  confPasswordError,
  phoneError,
  businessNameError,
  categoryError,
  isOtherCategory,
  otherCategoryError,
  addressError,
  descriptionError,
}) => {
  return (
    <Row className="m-0 ">
      <Form className="my-5 ls-form-main mx-auto" noValidate>
        <div className="text-center">
          <h6 className="text-black text-capitalize mt-5">
            Create Business Account
          </h6>
        </div>
        <AddBusinessFormView
          id="Full Name"
          label="Full Name"
          type="text"
          onChange={onNameChange}
          error={nameError}
        />
        <AddBusinessFormView
          id="Email"
          label="Email"
          type="email"
          onChange={onEmailChange}
          error={emailError}
        />
        <AddBusinessFormView
          id="Password"
          label="Password"
          type="password"
          onChange={onPasswordChange}
          error={passwordError}
        />
        <AddBusinessFormView
          id="Confirm Password"
          label="Password Confirmation"
          type="password"
          onChange={onConfPasswordChange}
          error={confPasswordError}
        />
        <AddBusinessPhoneView
          phoneError={phoneError}
          onPhoneChange={onPhoneChange}
        />
        <AddBusinessFormView
          id="Business Name"
          label="Business Name"
          type="text"
          onChange={onBusinessNameChange}
          error={businessNameError}
        />
        <AddBusinessCategoryView
          onCategoryChange={onCategoryChange}
          categoryError={categoryError}
          onOtherCategoryChange={onOtherCategoryChange}
          otherCategoryError={otherCategoryError}
        />

        <AddBusinessAdressView
          addressError={addressError}
          country="us"
          onAddressChange={onAddressChange}
          onAptChange={onAptChange}
        />
        <AddBusinessDescription
          descriptionError={descriptionError}
          onDescriptionChange={onDescriptionChange}
        />

        <div className="text-end mt-5">
          <Button
            type="button"
            className="shadow p-2 rounded"
            onClick={onCreateAccountHandler}
            style={{
              color: "#3b8543",
              fontWeight: "bold",
              backgroundColor: "white",
              border: "none",
              width: "auto",
            }}
          >
            Create Account
            <i className="bi bi-check-lg ms-2"></i>
          </Button>
        </div>
        <div className="text-end mt-3">
          <LinkContainer to={`/signin/business`}>
            <span className="ls-signup-text">Already have an account?</span>
          </LinkContainer>
        </div>
      </Form>
    </Row>
  );
};

export default AddBusinessView;
