import React from "react"
import { Row, Col } from "react-bootstrap"
import { DropdownCategory } from "./index"
import SearchBox from "../SearchBox"

const ServiceScreenTop = ({ setCategoryName }) => {
  return (
    <Row className='m-0 p-0d-flex justify-content-center top-main'>
      <Col xl={8}>
        <Row>
          <Col>
            <h4 className='w-100% text-center  mt-5 text-orange'>DooMoble</h4>
          </Col>
        </Row>
        <Row>
          <Col>
            <h6 className='text-center'>
              Choose a mobile service to view the businesses that are available.
              Discover mobile services in your area!
            </h6>
          </Col>
        </Row>
        <Row>
          <Col>
            <SearchBox />
          </Col>
        </Row>
        <Row>
          <Col>
            <h6 className='text-center'>or choose category</h6>
          </Col>
        </Row>
        <Row className='m-0 justify-content-md-center '>
          <DropdownCategory setCategoryName={setCategoryName} />
        </Row>
      </Col>
    </Row>
  )
}

export default ServiceScreenTop
