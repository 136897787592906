export const hours = [
  "1",
  "2",
  "3",
  "4",
  "5",
  "6",
  "7",
  "8",
  "9",
  "10",
  "11",
  "12",
]
export const min = ["00", "15", "30", "45"]
export const td = ["AM", "PM"]
