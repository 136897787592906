import React from "react"
import { Button, Card, Col, Row } from "react-bootstrap"
import Icon1 from "../../assets/icons/Group30.svg"
import Icon2 from "../../assets/icons/group40.svg"
import Icon3 from "../../assets/icons/group50.svg"

const Engage = ({ engageClickHandler }) => {
  const engCard = (ic, title, text) => {
    return (
      <Card className='engage-card my-5'>
        <Card.Img
          className='mx-auto engage-card-image'
          variant='top'
          src={ic}
        />
        <Card.Body>
          <Card.Title className='engage-card-title'>{title}</Card.Title>
          <Card.Text className='engage-card-text'>{text}</Card.Text>
        </Card.Body>
      </Card>
    )
  }
  return (
    <Row className='mx-auto text-center mb-5 bg-black d-flex justify-content-center'>
      <Col xl={8} xs={12} md={12}>
        <Row className='mx-auto text-center'>
          <h4 className=' mt-5'>
            A better way to &nbsp;
            <span className='engage-title-span'>engage &nbsp;</span>
            your customers.
          </h4>
        </Row>
        <Row className='w-100'>
          <Col lg={4} md={4} sm={12}>
            {engCard(
              Icon1,
              "Save time",
              "With online scheduling you’ll save time and be more efficient."
            )}
          </Col>
          <Col lg={4} md={4} sm={12}>
            {engCard(
              Icon2,
              "Grow your business",
              "Watch your profits and efficiency soar. You'll expand faster than you ever thought possible"
            )}
          </Col>
          <Col lg={4} md={4} sm={12}>
            {engCard(
              Icon3,
              "Bill clients",
              "Bill clients when they schedule an appointment with you!"
            )}
          </Col>
        </Row>
        <Row className='m-0 p-0 d-flex justify-content-center w-100 mb-5'>
          <Col xl={3} md={6} sm={12}>
            <Button
              variant='outline-dark '
              className='engage-btn w-100'
              onClick={engageClickHandler}
            >
              Get Listed on DooMoble
            </Button>
          </Col>
        </Row>
      </Col>
    </Row>
  )
}

export default Engage
