import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Modal, Button } from "react-bootstrap";
import { newAppointmentEmail } from "../../assets/email/newAppointmentEmail";
import {
  UserDetailsBD,
  BusinessInfoBD,
  LocationBD,
  ServicesBD,
  SummaryBD,
  DataSelectBD,
} from "../BusinessDetails";
import { Loader } from "../../components";
import { makeAppointment, sendEmail, listServiceDetails } from "../../actions";
import {
  AccordionItems,
  formatDate,
} from "../userDashborad/components/modalAppointment";
import moment from "moment";

const ModalBD = ({ showModal, handleCloseModal, showToast }) => {
  const [loading, setLoading] = useState(true);
  const [showMessage, setShowMessage] = useState(false);
  const [businessInfo, setBusinessInfo] = useState({
    name: "",
    avatar: "",
    rating: 0,
  });

  const [location, setLocation] = useState([]);
  const [successShow, setSuccessModal] = useState(false);
  const [successMessage, setSuccessMessage] = useState();
  const [services, setServices] = useState([]);
  const [appointments, setAppointments] = useState([]);
  const [apptLocation, setApptLocation] = useState();
  const [apptServices, setApptServices] = useState([]);
  const [apptDateTime, setApptDateTime] = useState();
  const [apptSchedule, setApptSchedule] = useState();
  const dispatch = useDispatch();
  const history = useNavigate();

  const { userInfo } = useSelector((state) => state.userLogin);
  const { service } = useSelector((state) => state.serviceDetails);

  useEffect(() => {
    if (userInfo && service !== undefined) {
      setBusinessInfo({
        name: service.businessName,
        avatar: service.avatar.path,
        rating: service.rating,
      });

      setLocation(service.locations.map((s) => s));
      setServices(service.services.map((s) => s));
      setLoading(false);
    }

    if (service) {
      let sers = service.appointments.map((item) => {
        return {
          ...item,
          dateFormatted: formatDate(item.datetime, "medium", "short"),
        };
      });

      setAppointments(sers);
      console.log(sers, "apps are");
      // setAppointments(sers)
    }
  }, [userInfo, service]);

  const clickLocationHandler = (loc) => {
    setApptLocation(loc);
    if (loc) {
      const temp = service.schedule.find(
        (x) => x.location.address === loc.address
      );
      setApptSchedule(temp);
    }
  };
  const clickServiceHandler = (ser) => {
    if (apptServices.length > 0) {
      if (apptServices.findIndex((s) => s._id === ser._id) > -1) {
        const temp = apptServices.filter((s) => s._id !== ser._id);

        setApptServices(temp);
      } else {
        // setFilteredAppts(appointments.filter((item) => {}))
        setApptServices((old) => [...old, ser]);
      }
    } else {
      setApptServices((old) => [...old, ser]);
    }
  };

  const clickDateHandler = (date) => {
    if (apptLocation === undefined) {
      setShowMessage(false);
    } else {
      setShowMessage(true);
      setApptDateTime(date);
    }
  };

  let tempAppointments = [...appointments];
  tempAppointments = tempAppointments.map((item) => {
    return {
      ...item,
      dateFormatted: moment(item.dateFormatted, "MMM DD, YYYY, h:mm A").format(
        "MMM DD, YYYY, HH:mm A"
      ),
    };
  });
  tempAppointments = tempAppointments.filter((appointment) => {
    if (apptLocation) {
      if (appointment.location.address == apptLocation.address) return true;
      else return false;
    } else {
      return false;
    }
  });

  // tempAppointments = tempAppointments.filter((appointment) => {
  //   if (apptServices && apptServices.length > 0) {
  //     const appointmentServices = appointment.services.map((item) => item.name)
  //     return apptServices.find((item) =>
  //       appointmentServices.includes(item.name)
  //     )
  //   } else {
  //     return true
  //   }
  // })
  tempAppointments = tempAppointments.filter((appointment) => {
    var moment1 = moment(appointment.dateFormatted, "MMM DD, YYYY");
    var moment2 = moment(apptDateTime, "MM/D/YYYY");
    return moment1.isSame(moment2, "day");
  });

  // tempAppointments = tempAppointments.filter((appointment) => {
  //   var moment1 = moment(appointment.dateFormatted, 'MMM DD, YYYY')
  //   var moment2 = moment(apptDateTime, 'MM/D/YYYY')
  //   return moment1.isSame(moment2, 'day')
  // })

  //handle appointment function
  const handleAppointment = () => {
    const dateString = apptDateTime.toISOString();

    if (
      apptLocation !== undefined &&
      apptServices.length > 0 &&
      apptDateTime !== undefined
    ) {
      const messText = newAppointmentEmail();

      let alreadyBooked = tempAppointments.find((item) =>
        item.dateFormatted.includes(moment(apptDateTime).format("HH:mm A"))
      );
      if (alreadyBooked) {
        alert("Please select a time that is available");
        return;
      }

      showToast();

      dispatch(
        makeAppointment(
          userInfo._id,
          service._id,
          apptServices,
          apptLocation,
          dateString
        )
      );
      dispatch(
        sendEmail({
          name: service.name,
          phnub: service.phonenumber,
          email: service.email,
          message: messText,
          to: service.email,
          subject: `DooMoble`,
        })
      );
      dispatch(listServiceDetails(service._id, service.category));

      // dispatch(
      //   sendEmail({
      //     name: service.name,
      //     phnub: service.phonenumber,
      //     email: service.email,
      //     message: messText,
      //     to: service.email,
      //     subject: `DooMoble`,
      //   })
      // )
      handleCloseModal(false);
      setTimeout(() => {
        history(0);
      }, 1000);
    } else {
      return;
    }
  };

  return (
    <>
      <Modal
        show={showModal}
        onHide={handleCloseModal}
        backdrop="static"
        centered
        size="xl"
        className="modal-dialog-scrollable text-black"
      >
        {/* *******Header******* */}
        <Modal.Header closeButton className="btn-close-white border-0">
          <Modal.Title as="h6" className="text-white">
            Schedule an Appointment
          </Modal.Title>
        </Modal.Header>
        {/* *******Body******* */}
        <Modal.Body className="text-black d-flex flex-column min-vh-100">
          {loading ? (
            <Loader />
          ) : (
            <>
              <UserDetailsBD
                avatar={
                  userInfo.avatar !== undefined ? userInfo.avatar.path : ""
                }
                name={userInfo.name}
              />
              <hr className="border" />
              <BusinessInfoBD
                avatar={businessInfo.avatar !== "" && businessInfo.avatar}
                name={businessInfo.name}
                rating={businessInfo.rating}
              />
              <hr className="border" />
              <LocationBD
                location={location}
                clickLocationHandler={clickLocationHandler}
                apptLocation={apptLocation}
              />
              <hr className="border" />
              <ServicesBD
                services={services}
                clickServiceHandler={clickServiceHandler}
                apptServices={apptServices}
              />
              <hr className="border" />

              <DataSelectBD
                clickDateHandler={clickDateHandler}
                apptDateTime={apptDateTime}
                locationDOF={apptSchedule}
                showMessage={showMessage}
                tempAppointments={tempAppointments}
              />

              <hr className="border" />
              <SummaryBD
                name={businessInfo.name}
                location={apptLocation}
                services={apptServices}
                apptDateTime={apptDateTime}
              />
            </>
          )}
        </Modal.Body>
        {/* *******Footer****** */}
        <Modal.Footer as="div" className="mt-auto d-flex justify-content-start">
          <Button
            className="float-left shadow border-0"
            variant="secondary"
            onClick={handleAppointment}
            style={{
              background: "transparent",
              color: "green",
              fontWeight: "600",
            }}
          >
            Schedule Appointment
            <i className="bi bi-alarm-fill ms-2" style={{ color: "green" }}></i>
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ModalBD;
