import React, { useEffect, useState } from "react"
import { Row, ListGroup, Col } from "react-bootstrap"

const ModalScheduleDO = ({ daysoFF, setSchedule }) => {
  const [daysOff, setDaysOff] = useState([])

  useEffect(() => {
    if (daysoFF !== [] && daysoFF !== undefined) {
      setDaysOff(daysoFF.daysOff)
    }
  }, [daysoFF])

  const dw = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ]
  const checkDaysOff = (day) => {
    if (daysOff) {
      return daysOff.includes(day)
    }
  }

  /**
   * Add days off handler
   */
  const daysOffHandler = (day) => {
    let newDOF = daysOff
    if (!daysOff.includes(day)) {
      newDOF.push(day)
      setDaysOff(newDOF)
      setSchedule((prev) => ({
        ...prev,
        daysoff: { daysOff: newDOF },
      }))
    } else {
      const nnewDOF = daysOff.filter((item) => item !== day)
      setDaysOff(nnewDOF)
      setSchedule((prev) => ({
        ...prev,
        daysoff: { daysOff: nnewDOF },
      }))
    }
  }
  return (
    <Row className='w-100 m-0 '>
      <ListGroup horizontal>
        <Row className='m-0 p-0'>
          {dw.map((d, index) => (
            <Col lg={1} className='w-auto mx-0' key={index}>
              <ListGroup.Item
                as='li'
                active={checkDaysOff(d)}
                className='schedule-listgroup my-2 border  rounded p-2'
                onClick={() => daysOffHandler(d)}
              >
                {checkDaysOff(d) && (
                  <i
                    className='bi bi-check-circle-fill me-1 '
                    style={{ color: "orange" }}
                  ></i>
                )}
                <span style={{ fontSize: "0.8rem" }}>{d}</span>
              </ListGroup.Item>
            </Col>
          ))}
        </Row>
      </ListGroup>
    </Row>
  )
}

export default ModalScheduleDO
