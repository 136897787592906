import React from "react"
import { Row, Col } from "react-bootstrap"
import BusinessDetailsCarusel from "./BusinessDetailsCarusel"
import BusinessDetailsSchedule from "./BusinessDetailsSchedule"

const BusinessDetailsSecondSection = ({
  gallery,
  disableButton,
  handlAddr,
  locationSer,
  idLocation,
  services,
  service,
  defaultMap,
  googleKey,
  scheduleHandler,
}) => {
  return (
    <Row className='mx-0 p-0 mt-5'>
      <Col xl={8} md={12} xs={12}>
        <BusinessDetailsCarusel gallery={gallery} />
      </Col>
      <Col xl={4} md={12} xs={12} className='border my-4 my-xl-0 px-0'>
        <BusinessDetailsSchedule
          disableButton={disableButton}
          handlAddr={handlAddr}
          locationSer={locationSer}
          idLocation={idLocation}
          services={services}
          googleKey={googleKey}
          defaultMap={defaultMap}
          service={service}
          scheduleHandler={scheduleHandler}
        />
      </Col>
    </Row>
  )
}

export default BusinessDetailsSecondSection
