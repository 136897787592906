import React from "react";
import ReactDOM from "react-dom/client";
// import "bootstrap/dist/css/bootstrap.min.css";
import "./index.css";
import "./styles/main.scss";
import { Provider } from "react-redux";
import store from "./store.js";
import App from "./App";
import "bootstrap-icons/font/bootstrap-icons.css";
import axios from "axios";
import ErrorBoundary from "./components/ErrorBoundary.jsx";
import { BrowserRouter as Router } from "react-router-dom";
axios.interceptors.request.use((config) => {
  config.baseURL = "https://backend.doomoble.com";

  // config.baseURL = "http://localhost:4000";
  return config;
});
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Provider store={store}>
    <Router>
      <ErrorBoundary>
        <App />
      </ErrorBoundary>
    </Router>
  </Provider>
);
