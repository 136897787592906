import React, { useEffect, useState } from "react"
import { useParams, useNavigate } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import { listServiceDetails } from "../actions/serviceActions"
import {
  Button,
  Col,
  Container,
  Image,
  Row,
  Stack,
  Carousel,
} from "react-bootstrap"
import {
  Raiting,
  Loader,
  Message,
  Persona,
  ServicesTable,
  MapContainer,
} from "../components"
import { toast } from "react-toastify"
import {
  hoursHelper,
  addDaysOffCalendar,
  pickHoursHelper,
} from "../components/Utils.js"
import GoogleMapReact from "google-map-react"
import {
  BusinessPreviewTop,
  BusinessPrreviewSecondSection,
  BusinessPreviewDocumentTable,
} from "../components/businessPreview"

const BusinessPreview = ({ googleKey }) => {
  const { category } = useParams()
  const { id } = useParams()
  const dispatch = useDispatch()
  const [idLocation, setIdLocation] = useState(0)
  //state for location
  const [disableButton, setDisableButton] = useState({
    left: true,
    right: false,
  })
  const [location, setLocation] = useState([])
  const [imagesGlr, setImagesGlr] = useState([])
  const [servicesList, setServicesList] = useState([])
  const [services, setServices] = useState({})
  const [defaultMap, setDefaultMap] = useState()

  //load service
  useEffect(() => {
    setLocation([])
    dispatch(listServiceDetails(id, category))
  }, [])

  //slice service
  const { loading, error, service } = useSelector(
    (state) => state.serviceDetails
  )

  useEffect(() => {
    if (error) {
      toast.error(error)
    }
  }, [error])

  useEffect(() => {
    if (service !== undefined) {
      setServices(service)
      setLocation(service.locations.map((s) => s))
      setImagesGlr(service.gallery.map((s) => s))
      setServicesList(service.services.map((s) => s))
      if (service.locations.length === 1) {
        setDisableButton({ left: true, right: true })
      } else {
        setDisableButton({ left: true, right: false })
      }
    }
  }, [service])

  useEffect(() => {
    if (location !== undefined) {
      if (location.length > 0) {
        setDefaultMap({
          center: {
            lat: location[0].latitude,
            lng: location[0].longitude,
          },
          zoom: 11,
        })
      }
    }
  }, [location])
  // handler address change
  const handlAddr = (p) => {
    if (p === "right") {
      setIdLocation(idLocation + 1)
      setDefaultMap({
        center: {
          lat: location[idLocation + 1].latitude,
          lng: location[idLocation + 1].longitude,
        },
        zoom: 11,
      })
      if (idLocation + 1 === service.locations.length - 1) {
        setDisableButton({ left: false, right: true })
      }
    } else {
      setDefaultMap({
        center: {
          lat: location[idLocation - 1].latitude,
          lng: location[idLocation - 1].longitude,
        },
        zoom: 11,
      })
      setIdLocation(idLocation - 1)
      if (idLocation - 1 === 0) {
        setDisableButton({ left: true, right: false })
      }
    }
  }

  return (
    <Container fluid className='bg-white text-black m-0 p-0'>
      <Row className='d-flex justify-content-center m-0 p-0'>
        <Col xl={8} md={9} xs={12}>
          {loading && <Loader />}
          <BusinessPreviewTop
            avatar={service ? service.avatar.path : []}
            name={service ? service.businessName : ""}
            location={location ? location : []}
            locationId={idLocation ? idLocation : 0}
            rating={
              service
                ? {
                    rating: service.rating,
                    ratingNumber: service.ratingNumber,
                  }
                : {}
            }
          />
          <BusinessPrreviewSecondSection
            gallery={imagesGlr ? imagesGlr : []}
            disableButton={disableButton}
            handlAddr={handlAddr}
            locationSer={location ? location : []}
            idLocation={idLocation ? idLocation : 0}
            services={services ? services : []}
            service={service ? service : []}
            googleKey={googleKey}
            defaultMap={defaultMap}
          />

          <div className='border p-3 mt-3'>
            <h5>About Us</h5>
            {service !== undefined
              ? service.details
                  .split("/n")
                  .map((item, i) => <p key={i}>{item}</p>)
              : ""}
          </div>
          <div className='mb-5'>
            <h5>Services</h5>
            {service !== undefined ? <ServicesTable services={service} /> : ""}
            <BusinessPreviewDocumentTable business={service} />
          </div>
        </Col>
      </Row>
    </Container>
  )
}

export default BusinessPreview
