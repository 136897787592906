import React from "react"
import { Row, Alert } from "react-bootstrap"
import LunchForm from "./LunchForm"

const LunchView = ({
  lunch,
  isLunchError,
  isLunch,
  setIsLunch,
  startHoursHandler,
  startMinutesHandler,
  startTdHandler,
  endHoursHandler,
  endMinutesHandler,
  endTdHandler,
}) => {
  return (
    <>
      <Row>
        <div className='form-check p-2'>
          <input
            className='schedule-form-check-input form-check-input ms-4'
            type='checkbox'
            defaultChecked={isLunch}
            name='flexRadioDefault'
            id='addlunch'
            onClick={() => setIsLunch(!isLunch)}
          />
          <label className='form-check-label ms-2' htmlFor='addlunch'>
            {isLunch ? "Lunch" : "Add lunch?"}
          </label>
        </div>
      </Row>
      {isLunch && (
        <Row className='w-100 m-0'>
          <LunchForm
            name='Lunch Start'
            hourV={lunch.start.hours}
            minV={lunch.start.minutes}
            tdV={lunch.start.td}
            isLunchError={isLunchError}
            hoursHandler={startHoursHandler}
            minutesHandler={startMinutesHandler}
            tdHandler={startTdHandler}
          />
          <LunchForm
            name='Lunch End'
            hourV={lunch.end.hours}
            minV={lunch.end.minutes}
            tdV={lunch.end.td}
            isLunchError={isLunchError}
            hoursHandler={endHoursHandler}
            minutesHandler={endMinutesHandler}
            tdHandler={endTdHandler}
          />
          {isLunchError ? (
            <Alert variant='danger' className='text-red'>
              Please check lunch times. End time can't be less than start time.
            </Alert>
          ) : (
            ""
          )}
        </Row>
      )}
    </>
  )
}

export default LunchView
