import React from "react"

let shift = {
  start: { hours: "1", minutes: "00", td: "AM" },
  end: { hours: "1", minutes: "00", td: "AM" },
}

const addShiftStartHour = (hour) => {
  shift.start.hours = hour
}
const addShiftStartMinutes = (min) => {
  shift.start.minutes = min
}
const addShiftStartTd = (td) => {
  shift.start.td = td
}
const addShiftEndHour = (hour) => {
  shift.end.hours = hour
}
const addShiftEndMinutes = (min) => {
  shift.end.minutes = min
}
const addShiftEndTd = (td) => {
  shift.end.td = td
}

const getShift = () => {
  return shift
}
/**
 * Check shift time
 */
const checkShift = () => {
  const sh =
    shift.start.td === "PM"
      ? 12 + Number(shift.start.hours)
      : Number(shift.start.hours)
  const sm =
    Number(shift.start.minutes) === 0 ? 0 : Number(shift.start.minutes) / 60
  const st = sh + sm
  const eh =
    shift.end.td === "PM"
      ? 12 + Number(shift.end.hours)
      : Number(shift.end.hours)
  const em =
    Number(shift.end.minutes) === 0 ? 0 : Number(shift.end.minutes) / 60
  const et = eh + em
  return st < et
}

const setShift = (oldShift) => {
  shift.start = oldShift.start
  shift.end = oldShift.end
}
export {
  addShiftStartHour,
  addShiftStartMinutes,
  addShiftStartTd,
  addShiftEndHour,
  addShiftEndMinutes,
  addShiftEndTd,
  getShift,
  setShift,
  checkShift,
}
