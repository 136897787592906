import { useState } from "react"
import { Stack, Container, Row, Form, FormGroup, Button } from "react-bootstrap"
import { useNavigate, useLocation } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import { changePassword } from "../actions"
import { toast } from "react-toastify"

const ResetScreen = () => {
  const [password, setPassword] = useState("")
  const [confPassword, setConfPassword] = useState("")
  const [errorPas, setErrorPas] = useState()
  const [errorConfPas, setErrorConfPas] = useState()

  const history = useNavigate()
  const dispatch = useDispatch()
  const urlLocation = useLocation()
  const userId = urlLocation.pathname.split("/")[2]

  const { changePassInfo } = useSelector((state) => state.changeUserPassword)

  const validateInfo = () => {
    setErrorConfPas("")
    setErrorPas("")
    if (password === "") {
      setErrorPas("Please enter password")
    } else {
      if (password.length < 6) {
        setErrorPas("Please enter correct password 6 characters long")
      }
    }
    if (confPassword !== password) {
      setErrorConfPas("Passwords don't mutch")
    }
  }

  const submitHandler = (e) => {
    e.preventDefault()
    validateInfo()
    if (errorPas === "" || errorPas === undefined) {
      if (password !== "") {
        if (confPassword === password) {
          toast.success("You have successfully changed your account password.")
          dispatch(changePassword(userId, password))
          history("/")
        }
      }
    }
  }
  return (
    <Stack direction='vertical' className='bg-white text-muted'>
      <Container>
        <Row className='m-0 '>
          <Form
            className='my-5 ls-form-main mx-auto'
            onSubmit={submitHandler}
            noValidate
          >
            <div className='text-center'>
              <h6 className='text-black text-capitalize mt-5'>Set password</h6>
            </div>
            <FormGroup>
              <Form.Label as='p' style={{ fontSize: "0.8rem" }}>
                New password
              </Form.Label>
              <Form.Control
                type='password'
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                isInvalid={errorPas}
              />
              <Form.Control.Feedback type='invalid'>
                {errorPas}
              </Form.Control.Feedback>
            </FormGroup>
            <FormGroup>
              <Form.Label as='p' style={{ fontSize: "0.8rem" }}>
                Confirm new password
              </Form.Label>
              <Form.Control
                type='password'
                value={confPassword}
                onChange={(e) => setConfPassword(e.target.value)}
                isInvalid={errorConfPas}
              />
              <Form.Control.Feedback type='invalid'>
                {errorConfPas}
              </Form.Control.Feedback>
            </FormGroup>

            <div className='text-end mt-4'>
              <Button type='submit' variant='dark'>
                Reset Password
              </Button>
            </div>
          </Form>
        </Row>
      </Container>
    </Stack>
  )
}

export default ResetScreen
