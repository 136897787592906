import React, { useEffect, useState } from "react"
import { Image } from "react-bootstrap"

const Persona = ({ img, letter }) => {
  return (
    <div className='persona shadow  d-flex justify-content-center align-items-center'>
      <Image className='image-persona' src={img} />
    </div>
  )
}

export default Persona
