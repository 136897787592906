import React from "react"
import { Row, Col } from "react-bootstrap"
import LunchFormSelect from "./LunchFormSelect"
import { hours, min, td } from "../model/LunchData"

const LunchForm = ({
  name,
  hourV,
  minV,
  tdV,
  isLunchError,
  hoursHandler,
  minutesHandler,
  tdHandler,
}) => {
  return (
    <Col lg={6} md={5} xs={12} className='border-end m-0 p-3 '>
      <Row>
        <span className={isLunchError ? "text-danger" : ""}>{name}</span>
        <LunchFormSelect
          val={hourV}
          err={isLunchError}
          arr={hours}
          handler={hoursHandler}
        />
        <LunchFormSelect
          val={minV}
          err={isLunchError}
          arr={min}
          handler={minutesHandler}
        />
        <LunchFormSelect
          val={tdV}
          err={isLunchError}
          arr={td}
          handler={tdHandler}
        />
      </Row>
    </Col>
  )
}

export default LunchForm
