import React from "react"
import { Form } from "react-bootstrap"
import NumberFormat from "react-number-format"

const AddBusinessPhoneView = ({phoneError,onPhoneChange}) => {
  return (
    <Form.Group className='my-3'>
      <Form.Label className='sgnup-form-label'>Phone Number</Form.Label>
      <NumberFormat
        format='(###) ###-####'
        mask='_'
        isNumericString={true}
        customInput={Form.Control}
        onValueChange={(e) => onPhoneChange(e.floatValue.toString())}
        isInvalid={phoneError}
      />
      <Form.Control.Feedback type='invalid'>{phoneError}</Form.Control.Feedback>
    </Form.Group>
  )
}

export default AddBusinessPhoneView
