import React from 'react'
import { Link } from 'react-router-dom'
import { Container, Row, Col, Card } from 'react-bootstrap'
import { SvgIcon } from '../index'
import { padding } from '@mui/system'

const HomeScreenServices = ({ mainServices }) => {
  return (
    <Container fluid className='bg-white'>
      <Row className='d-flex justify-content-center'>
        <Col xl={8} md={12} xs={12}>
          <Row className='m-0 p-0'>
            {mainServices.length > 0 &&
              mainServices.map((x) => (
                <Col lg={4} md={6} sm={12} key={x.shortName}>
                  <Link
                    to={`/search?category=${x.shortName}`}
                    className='servicess-card-link text-black'
                  >
                    <Card className=' bg-white border-0 text-center p-5'>
                      <div
                        className=' mx-auto bg-black rounded-circle '
                        style={{
                          width: '4.6rem',
                          height: '4.6rem',
                          padding: '0.7rem',
                        }}
                      >
                        <SvgIcon
                          className='w-100'
                          stroke='white'
                          fill='white'
                          comp={x.icon.element}
                          size={x.icon.size}
                          sz='50px'
                        />
                      </div>

                      <Card.Body>
                        <Card.Title className='servicess-card-title'>
                          {x.name}
                        </Card.Title>
                        <Card.Text className='servicess-card-text'>
                          Show businesses
                          <i className='fa-solid fa-arrow-right'></i>
                        </Card.Text>
                      </Card.Body>
                    </Card>
                  </Link>
                </Col>
              ))}
          </Row>
        </Col>
      </Row>
    </Container>
  )
}

export default HomeScreenServices
