import React, { useEffect, useState } from 'react'
import { Container } from 'react-bootstrap'
import { useSelector } from 'react-redux'

import {
  Engage,
  Review,
  VideoPlayer,
  TopHomeScreen,
  HomeScreenServices,
  AddBusiness,
  HomeScreenMap,
} from '../components/homeScreen'
import { useNavigate } from 'react-router-dom'

const HomeScreen = () => {
  const [isUser, setIsUser] = useState(false)
  const { userInfo } = useSelector((state) => state.userLogin)
  useEffect(() => {
    if (userInfo) {
      setIsUser(true)
    }
  }, [userInfo])
  const [mainServices, setMainServices] = useState([])
  const { settingsInfo } = useSelector((state) => state.settingsUp)
  useEffect(() => {
    if (settingsInfo) {
      setMainServices(settingsInfo[0].mainServices)
    }
  }, [settingsInfo])

  const history = useNavigate()
  const searchFilter = (keyword) => {
    if (keyword !== '') {
      history({ pathname: '/search', search: `?keyword=${keyword}` })
    }
  }
  const buttonClickHandler = () => {
    history(`/addbusiness`)
  }
  const engageClickHandler = () => {
    history(`/addbusiness`)
  }
  return (
    <Container fluid className='bg-white m-0 p-0'>
      <VideoPlayer />
      <TopHomeScreen searchFilter={searchFilter} isUser={isUser} />
      <HomeScreenServices mainServices={mainServices} />
      <AddBusiness buttonClickHandler={buttonClickHandler} />
      <HomeScreenMap />
      <Review />
      <Engage engageClickHandler={engageClickHandler} />
    </Container>
  )
}

export default HomeScreen
