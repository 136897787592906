import React from "react";
import Geocode from "react-geocode";

// locations: [
//   {
//     address: { type: String, required: true },
//     latitude: { type: Number, required: true },
//     longitude: { type: Number, required: true },
//   },
// ],

let newBusiness = {
  details: "",
  locations: {},
  phone: "",
  email: "",
  name: "",
  password: "",
  confPassword: "",
  categoryName: "",
  category: "",
  businessName: "",
};
let addBusinessError = {
  nameError: false,
  emailError: false,
  passwordError: false,
  confPasswordError: false,
  phoneError: false,
  businessNameError: false,
  categoryError: false,
  otherCategoryError: false,
  addressError: false,
  descriptionError: false,
};

const onNameChange = (val) => {
  newBusiness.name = val;
};
const onEmailChange = (val) => {
  newBusiness.email = val;
};
const onPasswordChange = (val) => {
  newBusiness.password = val;
};
const onConfPasswordChange = (val) => {
  newBusiness.confPassword = val;
};
const onPhoneChange = (val) => {
  newBusiness.phone = val;
};
const onBusinessNameChange = (val) => {
  newBusiness.businessName = val;
};
const onCategoryChange = (val, categoryName) => {
  newBusiness.category = val;
  newBusiness.categoryName = categoryName;
};
const onOtherCategoryChange = (val) => {
  newBusiness.categoryName = val;
};
let googleKey = "";
const addGeoCode = (key) => {
  googleKey = key;
  Geocode.setApiKey(googleKey);
};

const onAddressChange = (val) => {
  const addr = { address: val, latitude: 0, longitude: 0 };
  const coordinates = Geocode.fromAddress(val).then(
    (response) => {
      const { lat, lng } = response.results[0].geometry.location;
      addr.latitude = lat;
      addr.longitude = lng;
    },
    (error) => {
      console.error(error);
    }
  );
  newBusiness.locations = addr;
};

const onAptChange = (val) => {
  if (newBusiness.locations !== {}) {
    const address = "apt. " + val + ", " + newBusiness.locations.address;
    newBusiness.locations.address = address;
  }
};
const onDescriptionChange = (val) => {
  newBusiness.details = val;
};

const validateForm = () => {
  //check name
  if (!newBusiness.name) {
    addBusinessError.nameError = "Please enter your Full Name.";
  } else {
    addBusinessError.nameError = false;
  }
  //check email
  if (!newBusiness.email) {
    addBusinessError.emailError = "Please enter your Email.";
  } else {
    var re = /\S+@\S+\.\S+/;
    if (!re.test(newBusiness.email.toLowerCase())) {
      addBusinessError.emailError = "Please enter valid Email.";
    } else {
      addBusinessError.emailError = false;
    }
  }
  //check password
  if (newBusiness.password === "") {
    addBusinessError.passwordError = "Please enter Password.";
  } else if (newBusiness.password.length < 6) {
    addBusinessError.passwordError = "Password must be 6 characters long.";
  } else if (newBusiness.password !== newBusiness.confPassword) {
    addBusinessError.confPasswordError = "Passwords not mutch.";
  } else {
    addBusinessError.passwordError = false;
    addBusinessError.confPasswordError = false;
  }
  //check phone number
  if (newBusiness.phone.length === 0) {
    addBusinessError.phoneError = "Please enter Phone Number.";
  } else if (newBusiness.phone.length < 10) {
    addBusinessError.phoneError = "Please enter correct Phone Number.";
  } else {
    addBusinessError.phoneError = false;
  }
  //check businessName
  if (!newBusiness.businessName) {
    addBusinessError.businessNameError = "Please enter Business Name";
  } else {
    addBusinessError.businessNameError = false;
  }
  //check category and other category
  if (!newBusiness.category) {
    addBusinessError.categoryError = "Please select Category.";
  } else {
    addBusinessError.categoryError = false;
  }
  if (newBusiness.category === "custom-other" && !newBusiness.categoryName) {
    addBusinessError.otherCategoryError = "Please enter Your Category.";
  } else {
    addBusinessError.otherCategoryError = false;
  }
  //check address

  if (!newBusiness.locations.address) {
    addBusinessError.addressError = "Please enter correct Address.";
  } else {
    addBusinessError.addressError = false;
  }
  //check description
  if (!newBusiness.details) {
    addBusinessError.descriptionError = "Please describe Your Business.";
  } else {
    addBusinessError.descriptionError = false;
  }
};

const createAccountHandler = (categoryNameList = []) => {
  addBusinessError = {
    nameError: false,
    emailError: false,
    passwordError: false,
    confPasswordError: false,
    phoneError: false,
    businessNameError: false,
    categoryError: false,
    otherCategoryError: false,
    addressError: false,
    descriptionError: false,
  };

  validateForm();

  if (
    newBusiness.category === "custom-other" &&
    categoryNameList.includes(newBusiness.categoryName.trim().toLowerCase())
  ) {
    addBusinessError.otherCategoryError =
      "This category item is already present in the category list. Please select from the existing options.";
  }

  if (
    !addBusinessError.nameError &&
    !addBusinessError.emailError &&
    !addBusinessError.passwordError &&
    !addBusinessError.confPasswordError &&
    !addBusinessError.phoneError &&
    !addBusinessError.businessNameError &&
    !addBusinessError.categoryError &&
    !addBusinessError.addressError &&
    !addBusinessError.otherCategoryError &&
    !addBusinessError.descriptionError
  ) {
    return false;
  } else {
    return addBusinessError;
  }
  //CREATE ACCOUNT HANDLER
};
const getBusiness = () => {
  return newBusiness;
};

export {
  onNameChange,
  onEmailChange,
  onPasswordChange,
  onConfPasswordChange,
  onPhoneChange,
  onBusinessNameChange,
  onCategoryChange,
  onOtherCategoryChange,
  onAddressChange,
  onAptChange,
  onDescriptionChange,
  createAccountHandler,
  addGeoCode,
  getBusiness,
};
