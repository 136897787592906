import React from 'react'
import { Row, Col } from 'react-bootstrap'
import phoneIcon from '../../assets/icons/phone.svg'
import emailIcon from '../../assets/icons/mailoutline.svg'

const ContentOrange = () => {
  return (
    <Row className='d-flex justify-content-center bg-orange m-0 p-0 h-100'>
      <Col xl={8} xs={12} md={10} className='my-5 mx-0'>
        <Row>
          <Col>
            <Row>
              <Col>
                <h5>
                  <img src={phoneIcon} alt='phone' /> Call Us
                </h5>
              </Col>
            </Row>
            <Row>
              <Col>
                <p>Tell us your needs and we’ll send you a free estimate</p>
              </Col>
            </Row>
            <Row>
              <Col>
                <h4>1-(800)-230-3618</h4>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row className='mt-5'>
          <Col>
            <Row>
              <Col>
                <h5>
                  <img src={emailIcon} alt='email' />
                  Email Us
                </h5>
              </Col>
            </Row>
            <Row>
              <Col>
                <span>
                  for general inquiries and questions, contact us via email.
                </span>
              </Col>
            </Row>
            <Row>
              <Col>
                <h4>
                  <a
                    href='mailto:contact@doomoble.com'
                    className='text-decoration-none text-white'
                  >
                    contact@doomoble.com
                  </a>
                </h4>
              </Col>
            </Row>
          </Col>
        </Row>
      </Col>
    </Row>
  )
}

export default ContentOrange
