import React from "react"
import { Row, Alert } from "react-bootstrap"
import ShiftForm from "./ShiftForm"

const ShiftView = ({
  shift,
  isShiftError,
  startHoursHandler,
  startMinutesHandler,
  startTdHandler,
  endHoursHandler,
  endMinutesHandler,
  endTdHandler,
}) => {
  return (
    <Row className='w-100 m-0 '>
      <ShiftForm
        name='Shift Start'
        hourV={shift.start.hours}
        minV={shift.start.minutes}
        tdV={shift.start.td}
        isShiftError={isShiftError}
        hoursHandler={startHoursHandler}
        minutesHandler={startMinutesHandler}
        tdHandler={startTdHandler}
      />
      <ShiftForm
        name='Shift End'
        hourV={shift.end.hours}
        minV={shift.end.minutes}
        tdV={shift.end.td}
        isShiftError={isShiftError}
        hoursHandler={endHoursHandler}
        minutesHandler={endMinutesHandler}
        tdHandler={endTdHandler}
      />
      {isShiftError && (
        <p style={{ color: "red" }}>
          Please check shift times. End time can't be less or same than start
          time.
        </p>
      )}
    </Row>
  )
}

export default ShiftView
