import React from "react";
import { FilterShow, PaginationCustom, ShowCategory } from "../serviceScreen";
import { useSelector } from "react-redux";
import { Spinner } from "react-bootstrap";

const ServiceScreenMainContext = ({
  setListView,
  serviceLenght,
  categoryName,
  setTitleDM,
  titleDM,
  listView,
  mainServices,
  totalPages,
  currentPage,
  onPrev,
  onNext,
  setPage,
}) => {
  const { loading } = useSelector((state) => state.serviceList);

  return (
    <>
      <FilterShow
        setListView={setListView}
        serviceLenght={serviceLenght}
        categoryName={categoryName}
        setTitleDM={setTitleDM}
        titleDM={titleDM}
      />
      {loading ? (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Spinner animation="border" style={{ color: "#F89402" }} />
        </div>
      ) : (
        <ShowCategory
          listView={listView}
          mainServices={mainServices}
          titleDM={titleDM}
        />
      )}

      <hr className="border" />
      <PaginationCustom
        totalPages={totalPages}
        currentPage={currentPage}
        onPrev={onPrev}
        onNext={onNext}
        setPage={setPage}
      />
    </>
  );
};

export default ServiceScreenMainContext;
