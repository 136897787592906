import React from "react"
import { Carousel, Image } from "react-bootstrap"

const BusinessPreviewCarusel = ({ gallery }) => {
  return (
    <Carousel className='resp-carousel-image'>
      {gallery.length > 0 &&
        gallery.map((i) => (
          <Carousel.Item
            key={i._id}
            className='text-center align-middle'
            style={{
              background: "#cccccc",
            }}
          >
            <Image
              className='d-block img-fluid w-100 rounded bg-gray align-middle resp-carousel-image-single'
              src={i.path}
              alt='galery'
              style={{
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
              }}
            />
          </Carousel.Item>
        ))}
    </Carousel>
  )
}

export default BusinessPreviewCarusel
