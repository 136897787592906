import React from "react"
import { Row, Col, Button, Image } from "react-bootstrap"

import { BlackButton } from "../UI"
const AboutUsAddBusiness = () => {
  return (
    <Row className='bg-white justify-content-center m-0 p-0'>
      <Col xl={8} md={12} xs={12} className='p-3'>
        <Row>
          <Col lg={6} md={6} sm={12}>
            <Row>
              <Col className='p-2'>
                <p className='text-black'>
                  DooMoble is designed for small entrepreneur who take their
                  Business to the customer. By creating easy online
                  accessibility, Customers can search, review and book
                  appointments in the comfort of their home and build great
                  relationships with their favorite small businesses in their
                  area. Customers and businesses are required to have a profile,
                  so reservations and easy contact can be maintained and
                  followed through. DooMoble is also designed to help customers
                  and businesses conduct safe transactions and encounters,
                  because at DooMoble, safety is first. We ensure that all users
                  of DooMoble identify themselves with government
                  Identification, pictures, online activity, email, current
                  address, and phone number. Plus, both parties will be a member
                  of your site. Yes! your site, once you’re a member you are
                  part of a growing community where you can tap into anywhere
                  you travel. So welcome to your DooMoble community and spread
                  the word
                </p>
              </Col>
            </Row>
            <Row className='d-flex justify-content-start my-4'>
              <Col xl={5} xs={12}>
                <BlackButton link='/addbusiness' text='Add Your Business' />
              </Col>
            </Row>
          </Col>
          <Col lg={6} md={6} sm={12}>
            <Row className='align-bottom'>
              <Col xs={12} md={6} xl={6} className='align-bottom mb-sm-2'>
                <Image
                  style={{
                    backgroundSize: "cover",
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "50% 50%",
                  }}
                  src='https://res.cloudinary.com/deveke/image/upload/v1655260537/tqycvtwco7gdm4kcmqnh.jpg'
                  fluid
                />
              </Col>
              <Col xs={12} md={6} xl={6} className='d-flex align-items-end'>
                <Image
                  src='https://res.cloudinary.com/deveke/image/upload/v1655757855/cpgjb7mv521hnaifkgxh.png'
                  fluid
                />
              </Col>
            </Row>
            <Row className='mt-2'>
              <Col xs={12} md={6} xl={6} className='d-flex align-items-start'>
                <Image
                  style={{ objectFit: "cover" }}
                  src='https://res.cloudinary.com/deveke/image/upload/v1655757900/kuman7pja4q3pja0wqtg.png'
                  fluid={true}
                />
              </Col>
              <Col xs={12} md={6} xl={6}>
                <Image
                  src='https://res.cloudinary.com/deveke/image/upload/v1655260514/softlouiwfb3bbyuzau8.png'
                  fluid
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </Col>
    </Row>
  )
}

export default AboutUsAddBusiness
