import { Col, Row } from "react-bootstrap";
import { bdstr } from "../strings";
import moment from "moment";

export const to24Format = (time) => {
  const min = time.minutes ? Number(time.minutes) : 0;
  if (time.td === "PM") {
    return {
      hours: Number(time.hours) + 12,
      minutes: min,
      td: time.td,
    };
  } else {
    return { hours: Number(time.hours), minutes: min, td: time.td };
  }
};

export const HoursCell = (props) => {
  return <Col className="m-1 p-0">{props.children}</Col>;
};

export const formatDate = (date, dstyle, tstyle) => {
  const fd = new Date(date);
  const formatedDate = new Intl.DateTimeFormat("en-US", {
    dateStyle: dstyle,
    timeStyle: tstyle,
  }).format(fd);
  return formatedDate;
};

export const RendMinutes = ({
  minArray,
  bussHour,
  clickMinHandler,
  tempAppointments,
}) => {
  let timings = minArray.map((item) => {
    return `${bussHour.hours}:${item.name}`;
  });

  let bookedTimes = [];

  tempAppointments = tempAppointments.map((item) => {
    let allDuration = 0;
    item.services.map((service) => {
      allDuration += service.duration;
    });

    item["serviceTimes"] = [
      moment(tempAppointments[0].dateFormatted, "MMM DD, YYYY, HH:mm A").format(
        "HH:mm"
      ),
    ];

    let loopLength = allDuration / 15;
    for (let i = 1; i <= loopLength; i++) {
      item.serviceTimes.push(
        moment(tempAppointments[0].dateFormatted, "MMM DD, YYYY, HH:mm A")
          .add(i * 15, "minutes")
          .format("HH:mm")
      );
    }

    return {
      ...item,
      allDuration,
    };
  });

  timings.map((minTime, index) => {
    let foundAppt = tempAppointments.find((item) => {
      return item.serviceTimes.includes(minTime);
    });

    if (foundAppt) return bookedTimes.push(index);
  });

  timings = timings.map((time) => time.split(":")[1]);
  minArray = minArray.filter((item) => {
    return timings.includes(item.name);
  });
  return (
    <Row className="m-0">
      {bussHour !== undefined ? (
        <>
          {bussHour.hours !== 0 ? (
            minArray.map((i, index) => (
              <HoursCell key={i.name} bookedTime={bookedTimes.includes(index)}>
                {i.inactive ? (
                  <div
                    className={"border rounded bd-hours-inactive"}
                  >{`Lunch: ${bussHour.hours}:${i.name} `}</div>
                ) : (
                  <div
                    style={{
                      cursor: bookedTimes.includes(index)
                        ? "not-allowed"
                        : "pointer",
                      backgroundColor: bookedTimes.includes(index)
                        ? "gainsboro"
                        : bussHour.min === Number(i.name)
                        ? "#282828"
                        : "white",
                    }}
                    className={
                      bussHour.min === Number(i.name)
                        ? "border rounded bd-hours-active"
                        : "border rounded bd-hours"
                    }
                    onClick={() => {
                      if (bookedTimes.includes(index))
                        alert("Time Already Booked");
                      else
                        clickMinHandler({
                          hours: bussHour.hours,
                          min: Number(i.name),
                        });
                    }}
                  >{`${
                    bussHour.hours < 13 ? bussHour.hours : bussHour.hours - 12
                  }:${i.name} `}</div>
                )}
              </HoursCell>
            ))
          ) : (
            <strong className="text-warning">{bdstr[15]}</strong>
          )}
        </>
      ) : (
        ""
      )}
    </Row>
  );
};
export const checkLunchTime = (hour, businessHours, setminArray) => {
  const stMin = businessHours.lunch.start.minutes;
  const endMin = businessHours.lunch.end.minutes;
  let newHa = [];

  let ha = [
    { name: "00", inactive: "inactive" },
    { name: "15", inactive: "inactive" },
    { name: "30", inactive: "inactive" },
    { name: "45", inactive: "inactive" },
  ];
  let ha2 = [
    { name: "00", inactive: "" },
    { name: "15", inactive: "" },
    { name: "30", inactive: "" },
    { name: "45", inactive: "" },
  ];
  if (hour === businessHours.lunch.start.hours) {
    if (hour === businessHours.lunch.end.hours) {
      if (stMin === 0 && endMin === 45) {
        newHa = [...ha.slice(0, -1), ...ha2.slice(3)];
      } else if (stMin === 0 && endMin === 30) {
        newHa = [...ha.slice(0, -2), ...ha2.slice(2)];
      } else if (stMin === 0 && endMin === 15) {
        newHa = [...ha.slice(0, -3), ...ha2.slice(1)];
      } else if (stMin === 15 && endMin === 30) {
        newHa = [...ha2.slice(0, -3), ...ha.slice(1, -2), ...ha2.slice(2)];
      } else if (stMin === 15 && endMin === 45) {
        newHa = [...ha2.slice(0, -3), ...ha.slice(1, -1), ...ha2.slice(3)];
      } else if (stMin === 30 && endMin === 45) {
        newHa = [...ha2.slice(0, -2), ...ha.slice(2, -1), ...ha2.slice(3)];
      }
    } else {
      if (stMin === 0) {
        newHa = ha;
      } else if (stMin === 15) {
        newHa = [...ha2.slice(0, -3), ...ha.slice(1)];
      } else if (stMin === 30) {
        newHa = [...ha2.slice(0, -2), ...ha.slice(2)];
      } else if (stMin === 45) {
        newHa = [...ha2.slice(0, -1), ...ha.slice(3)];
      }
    }
  } else {
    newHa = ha2;
  }
  setminArray(newHa);
};
