import React from "react"
import { Row } from "react-bootstrap"
import Rating from "@mui/material/Rating"
import Typography from "@mui/material/Typography"

const RatingView = ({ rating, setRating }) => {
  return (
    <Row>
      {rating === 0 ? (
        <>
          <Typography component='legend' className='ms-2 text-black'>
            Leave your feedback:
          </Typography>
          <Rating
            name='simple-controlled'
            value={rating}
            size='large'
            onChange={(event, newValue) => {
              setRating(newValue)
            }}
          />
        </>
      ) : (
        <>
          <Typography component='legend' className='ms-2 text-black'>
            Your feedback is:
          </Typography>
          <Rating
            name='simple-controlled'
            value={rating}
            size='large'
            readOnly
          />
        </>
      )}
    </Row>
  )
}

export default RatingView
