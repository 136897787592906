import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Dropdown } from "react-bootstrap";

const DropdownCategory = ({ setCategoryName }) => {
  /**
   * Get category from url params
   */
  const [searchParams, setSearchParams] = useSearchParams();
  const categoryParam = searchParams.get("category");
  const keyword = searchParams.get("keyword");
  const param_othername = searchParams.get("othername");
  const [category, setCategory] = useState();
  const [othername, setOthername] = useState();
  const settingsInfo = JSON.parse(localStorage.getItem("settingsInfo"));
  const [fullNameCategory, setFullNameCategory] = useState();

  useEffect(() => {
    setFullNameCategory("Pick a Category");
    setCategoryName("");
  }, []);
  useEffect(() => {
    if (categoryParam) {
      setCategory(categoryParam);
    } else {
      setCategory();
    }
  }, [categoryParam, param_othername]);
  /**
   * Full name category
   */
  const getFullName = () => {
    if (!category || keyword) {
      setFullNameCategory("Pick a Category");
      setCategoryName("");
    } else {
      if (settingsInfo !== undefined) {
        const findedMainCategory = settingsInfo[0].mainServices.find(
          (o) => o.shortName === category
        );
        if (findedMainCategory === undefined) {
          const findedUserCategory = settingsInfo[0].usersServices.find(
            (o) =>
              o?.otherName === param_othername ||
              o?.shortName === param_othername
          );

          setFullNameCategory(findedUserCategory?.name);
          setCategoryName(findedUserCategory?.name);
        } else {
          setCategoryName(findedMainCategory?.name);
          setFullNameCategory(findedMainCategory?.name);
        }
      }
    }
  };

  useEffect(() => {
    getFullName();
  }, [category, keyword]);

  /**
   * Click Category Handler
   */
  const history = useNavigate();

  const clickCategoryHandler = (shortName, name) => {
    setFullNameCategory(name);
    const nn = name.replace(/\s/g, "").toLowerCase();
    history(`/search?category=${shortName}&othername=${nn}`);
  };
  /**
   * Main return
   */

  return (
    <>
      <Dropdown className="my-3 text-center">
        <Dropdown.Toggle className="ss-dd-t w-100 mx-auto  text-center">
          {fullNameCategory}
        </Dropdown.Toggle>
        <Dropdown.Menu
          style={{ background: "white", maxWidth: "45rem" }}
          className="top-search w-100 border shadow"
        >
          {settingsInfo !== undefined &&
            settingsInfo[0].mainServices.map((ms, index) => (
              <Dropdown.Item
                key={index}
                className="ss-dd-i"
                onClick={() => clickCategoryHandler(ms.shortName, ms.name)}
              >
                {ms.name}
              </Dropdown.Item>
            ))}
          {settingsInfo !== null &&
            settingsInfo !== undefined &&
            settingsInfo[0].usersServices.map((us, index) => (
              <Dropdown.Item
                key={index}
                className="ss-dd-i"
                onClick={() => clickCategoryHandler(us.shortName, us.name)}
              >
                {us.name}
              </Dropdown.Item>
            ))}
        </Dropdown.Menu>
      </Dropdown>
    </>
  );
};

export default DropdownCategory;
