import React, { useEffect, useState } from 'react'
import { InputGroup, FormControl } from 'react-bootstrap'
import { useNavigate, useSearchParams } from 'react-router-dom'

const SearchBox = () => {
  const [keyword, setKeyword] = useState('')
  const history = useNavigate()
  const [searchParams, setSearchParams] = useSearchParams()
  const categoryParam = searchParams.get('category')
  const keywords = searchParams.get('keyword')

  useEffect(() => {
    if (categoryParam) {
      setKeyword('')
    } else if (keywords) {
      setKeyword(keywords)
    }
  }, [categoryParam, keywords])

  const searchClickHandler = (keyword) => {
    history(`/search?keyword=${keyword}`)
  }
  const enterKeyword = (e) => {
    setKeyword(() => e.target.value)
  }
  return (
    <>
      <InputGroup className='top-input my-3 mx-auto'>
        <FormControl
          type='text'
          placeholder='Search Businesses'
          value={keyword}
          className='top-search'
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              searchClickHandler(keyword)
            }
          }}
          onChange={(e) => enterKeyword(e)}
        ></FormControl>
      </InputGroup>
    </>
  )
}

export default SearchBox
