import React, { useRef } from 'react'
import { Form, Row, Col } from 'react-bootstrap'
import Autocomplete from 'react-google-autocomplete'

const AddBusinessAdressView = ({
  addressError,
  country,
  onAddressChange,
  onAptChange,
}) => {
  const inputRef = useRef(null)

  return (
    <Form.Group className='my-3'>
      <Form.Label className='sgnup-form-label'>Address</Form.Label>
      <Row>
        <Col lg={8} md={8} sm={12}>
          <Autocomplete
            className='w-100 rounded'
            placeholder='Street Address'
            apiKey={'AIzaSyCdhCCnXjntoxshGZhn2yXktwhMwNicMyI'}
            style={
              addressError
                ? { border: '0.05rem solid #c00', height: '2.4rem' }
                : { border: '0.05rem solid #dee2e6', height: '2.4rem' }
            }
            ref={inputRef}
            onPlaceSelected={(selected, a, c) => {
              onAddressChange(selected.formatted_address)
            }}
            options={{
              types: ['geocode', 'establishment'],
              componentRestrictions: { country },
            }}
            defaultValue=''
          />
        </Col>
        <Col lg={4} md={4} sm={12}>
          <Form.Control
            type='text'
            placeholder='Apt, Suite'
            onChange={(e) => onAptChange(e.target.value)}
          />
        </Col>
      </Row>

      {addressError && <span className='text-danger'>{addressError}</span>}
    </Form.Group>
  )
}

export default AddBusinessAdressView
