import { useState } from 'react'
import { ButtonShadow } from '../../../components'
import { Modal, Form } from 'react-bootstrap'
import FileViewer from 'react-file-viewer'
import { Viewer, Worker } from '@react-pdf-viewer/core'
import { zoomPlugin } from '@react-pdf-viewer/zoom'
import '@react-pdf-viewer/core/lib/styles/index.css'
import '@react-pdf-viewer/zoom/lib/styles/index.css'

const DocumentsTable = ({ arr, name, uplFun, delFun }) => {
  const [showModalUpload, setShowModalUpload] = useState(false)
  const [showModalDelete, setShowModalDelete] = useState(false)
  const [showModalPreview, setShowModalPreview] = useState(false)
  const [newUploadedFile, setNewUploadedFile] = useState()
  const [oldFile, setOldFile] = useState()
  const [fileType, setFileType] = useState('')
  const [filePath, setFilePath] = useState('')

  /** Functions for work with documents */
  const deleteHandle = (name) => {
    setShowModalDelete(true)
    setOldFile(name)
  }

  const uploadHandle = (name) => {
    setShowModalUpload(true)
    setOldFile(name)
  }

  /** Preview Handle */
  const previewHandle = (item) => {
    const tp = item.name.split('.').pop()
    const pth = item.path
    setFileType(tp.toString())
    setFilePath(pth)
    setShowModalPreview(true)
  }

  /** Upload document function */
  const saveFile = (e) => {
    setNewUploadedFile(e.target.files[0])
  }

  /** Toast for upload document */
  const toastUpload = () => {
    return (
      <Modal show={showModalUpload} onHide={() => setShowModalUpload(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Upload a File</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Control type='file' onChange={saveFile} />
        </Modal.Body>
        <Modal.Footer>
          <ButtonShadow
            bgcolor='#3b8543'
            color='white'
            text='Save'
            handleOnClick={() => {
              setShowModalUpload(false)
              uplFun(oldFile, newUploadedFile, name)
            }}
          />
          <ButtonShadow
            bgcolor='white'
            color='#d1372d'
            text='Cancel'
            handleOnClick={() => setShowModalUpload(false)}
          />
        </Modal.Footer>
      </Modal>
    )
  }
  const zoomPluginInstance = zoomPlugin()
  const { CurrentScale, ZoomIn, ZoomOut } = zoomPluginInstance
  /** Toast for preview document */
  const toastPreview = () => {
    return (
      <Modal show={showModalPreview} onHide={() => setShowModalPreview(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Upload a File</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {fileType === 'pdf' || fileType === 'PDF' ? (
            <div>
              <div className='d-flex justify-content-center'>
                <ZoomIn />
                <ZoomOut />
              </div>
              <Worker workerUrl='https://unpkg.com/pdfjs-dist@2.16.105/build/pdf.worker.min.js'>
                <Viewer fileUrl={filePath} plugins={[zoomPluginInstance]} />
              </Worker>
            </div>
          ) : (
            <FileViewer fileType={fileType} filePath={filePath} />
          )}
        </Modal.Body>
        <Modal.Footer>
          <ButtonShadow
            bgcolor='white'
            color='#d1372d'
            text='Close'
            handleOnClick={() => setShowModalPreview(false)}
          />
        </Modal.Footer>
      </Modal>
    )
  }
  /** Toast for delete document */
  const toastDelete = () => {
    return (
      <Modal show={showModalDelete} onHide={() => setShowModalDelete(false)}>
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <strong style={{ color: '#d1372d' }}>
            Are you sure you want to delete this document?
          </strong>
        </Modal.Body>
        <Modal.Footer>
          <ButtonShadow
            bgcolor='#d1372d'
            color='white'
            text='Delete'
            handleOnClick={() => {
              setShowModalDelete(false)
              delFun(oldFile, name)
            }}
          />
          <ButtonShadow
            bgcolor='white'
            color='#d1372d'
            text='Cancel'
            handleOnClick={() => setShowModalDelete(false)}
          />
        </Modal.Footer>
      </Modal>
    )
  }
  return (
    <>
      {showModalUpload ? toastUpload() : ''}
      {showModalDelete ? toastDelete() : ''}
      {showModalPreview ? toastPreview() : ''}

      <table className='table table-sm caption-top mt-3'>
        <caption style={{ color: '#415da5', fontWeight: '600' }}>
          {name}
        </caption>
        <tbody>
          {arr.map((x) => (
            <tr key={x.name}>
              <td className='w-100'>
                {name === 'Insurance'
                  ? 'Business Insurance'
                  : 'Business License'}
              </td>
              <td className='text-end '>
                <ButtonShadow
                  icon='bi bi-eye-fill'
                  color='#282828'
                  handleOnClick={() => previewHandle(x)}
                />
              </td>
              <td className='text-end'>
                <ButtonShadow
                  icon='bi bi-box-arrow-in-up'
                  color='#282828'
                  handleOnClick={() => uploadHandle(x.name)}
                />
              </td>
              <td className='text-end'>
                <ButtonShadow
                  icon='bi bi-trash-fill'
                  color='red'
                  handleOnClick={() => deleteHandle(x.name)}
                />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </>
  )
}

export default DocumentsTable
