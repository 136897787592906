import React from "react"
import { Col, ButtonGroup, Button } from "react-bootstrap"
import SideMenuButton from "./SideMenuButton"

const SideMenu = ({ typeOfPage, setTypeOfPage }) => {
  return (
    <Col lg={3} md={5} sm={12} className='border p-0'>
      <ButtonGroup vertical className='w-100 bg-white'>
        <SideMenuButton
          typeOfPage={typeOfPage}
          type='manage'
          icon='bi bi-list-task me-2'
          clickHandler={setTypeOfPage}
          text='Manage Appointments'
        />
        <SideMenuButton
          typeOfPage={typeOfPage}
          type='calendar'
          icon='bi bi-calendar3 me-2'
          clickHandler={setTypeOfPage}
          text='My Calendar'
        />
        <SideMenuButton
          typeOfPage={typeOfPage}
          type='account'
          icon='bi bi-person-circle me-2'
          clickHandler={setTypeOfPage}
          text='Account Manager'
        />
      </ButtonGroup>
    </Col>
  )
}

export default SideMenu
