import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { LinkContainer } from "react-router-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Container, Row, Stack } from "react-bootstrap";
import {
  getAppointments,
  getUserApptById,
  cancelAppointmentById,
  updateAppointment,
  getUserById,
} from "../actions";
import SideMenu from "./userDashborad/components/view/SideMenu";
import MainView from "./userDashborad/components/view/MainView";

const DbUser = () => {
  const [userId, setUserId] = useState("");
  const [userName, setUserName] = useState("");
  const [userInformation, setUserInformation] = useState();
  const [appts, setAppts] = useState();
  const [typeOfPage, setTypeOfPage] = useState("account");
  const dispatch = useDispatch();

  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  useEffect(() => {
    if (userInfo) {
      setUserId(userInfo._id);
      dispatch(getUserById(userInfo._id));
    }
  }, []);

  const { getUserBIDInfo, error } = useSelector((state) => state.getUserById);
  const { succes } = useSelector((state) => state.cancelAppointment);

  useEffect(() => {
    if (succes) {
      dispatch(getUserById(userInfo._id));
    }
  }, [succes]);

  useEffect(() => {
    if (error) {
      toast.error(error);
    }
    if (getUserBIDInfo) {
      setUserName(getUserBIDInfo.name);
      setUserInformation(getUserBIDInfo);
      dispatch(getAppointments(getUserBIDInfo.appointments));
    }
  }, [getUserBIDInfo, error]);

  const { apptsInfo } = useSelector((state) => state.userAppointment);

  useEffect(() => {
    if (apptsInfo) {
      setAppts(apptsInfo);
    }
  }, [apptsInfo]);

  // useEffect(() => {

  //   if (userInfo) {
  //     setUserId(userInfo._id)
  //     setUserName(userInfo.name)
  //     setAppts()
  //     dispatch(getUserById(userInfo._id))
  //     dispatch(getAppointments(userInfo.appointments))
  //   }
  // }, [userInfo])

  // useEffect(() => {
  //   if (apptsInfo !== undefined) {
  //     setAppts(apptsInfo.newArr)
  //     //   console.log(apptsInfo)
  //     //   if (apptsInfo.newArr !== undefined) {
  //     //   }
  //   }
  // }, [apptsInfo])

  // useEffect(() => {
  //   if (error) {
  //     toast.error(error)
  //   }
  // }, [error])

  // useEffect(() => {
  //   if (getUserBIDInfo) {
  //     setUserInformation(getUserBIDInfo)
  //     setUserName(getUserBIDInfo.name)
  //     setAppts()
  //     dispatch(getAppointments(getUserBIDInfo.appointments))
  //   }
  // }, [getUserBIDInfo])
  /**
   * Pages section
   */

  // useEffect(() => {
  //   if (userId) {
  //     dispatch(getUserById(userId))
  //   }
  // }, [typeOfPage])
  // const [uploadedAppt, setUploadAppt] = useState()

  // useEffect(() => {}, [])
  /**
   * Work with user information
   */
  // const [usrInfo, setUsrInfo] = useState("")
  // const { apptById } = useSelector((state) => state.getAppointmentsById)
  // useEffect(() => {
  //   if (userInfo !== undefined) {
  //     setUsrInfo(userInfo)
  //     dispatch(getUserApptById(userInfo._id))
  //   }
  // }, [userInfo])

  // useEffect(() => {
  //   if (apptById !== undefined) {
  //     setUploadAppt(apptById)
  //   }
  // }, [apptById])

  /**
   * Appointments section
   */
  //
  // const { apptsInfo } = useSelector((state) => state.userAppointment)
  // useEffect(() => {
  //   if (apptsInfo !== undefined) {
  //     setAppts({ apptsInfo })
  //   }
  // }, [apptsInfo])

  const cancelAppointment = (id) => {
    dispatch(cancelAppointmentById(id));
  };

  const updateAppointmentHandler = async (apt) => {
    dispatch(
      updateAppointment(apt._id, apt.services, apt.location, apt.datetime)
    );
    setAppts();
    dispatch(getAppointments(appts));
  };

  return (
    <Stack className="m-0 vh-80">
      {userInformation !== "" ? (
        <>
          <Container>
            <h6 className="text-black my-3">
              <strong>{`Welcome, ${userName}`}</strong>
            </h6>
          </Container>
          <hr className="m-0" />
          <Container className="border ">
            <Row>
              <SideMenu typeOfPage={typeOfPage} setTypeOfPage={setTypeOfPage} />
              <MainView
                typeOfPage={typeOfPage}
                appts={appts}
                usrInfo={userInformation}
                cancelAppointment={cancelAppointment}
                updateAppointmentHandler={updateAppointmentHandler}
              />
            </Row>
          </Container>
        </>
      ) : (
        ""
      )}
    </Stack>
  );
};

export default DbUser;
