import React, { useEffect, useState } from "react"
import { Form } from "react-bootstrap"

const AddBusinessFormView = ({ label, type, onChange, error }) => {
  return (
    <Form.Group className='my-3'>
      <Form.Label className='sgnup-form-label'>{label}</Form.Label>
      <Form.Control
        type={type}
        onChange={(e) => onChange(e.target.value)}
        isInvalid={error}
      />
      <Form.Control.Feedback type='invalid'>{error}</Form.Control.Feedback>
    </Form.Group>
  )
}

export default AddBusinessFormView
