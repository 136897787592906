import React, { useEffect, useState } from "react"
import { Row, Col } from "react-bootstrap"
import DocumentsTable from "./DocumentsTable"

const DocumentTable = ({ business }) => {
  const [docInfoIns, setDocInfoIns] = useState([])
  const [docInfoLic, setDocInfoLic] = useState([])

  const checkType = (arr) => {
    if (arr.type === "Insurance") {
      setDocInfoIns((y) => [...y, arr])
    } else if (arr.type === "License") {
      setDocInfoLic((y) => [...y, arr])
    }
  }
  useEffect(() => {
    if (business !== undefined) {
      business.documents.map((x) => checkType(x))
    }
  }, [business])

  return (
    <Row>
      <Col>
        <strong>Business Documents</strong>
        {docInfoIns.length === 0 && docInfoLic.length === 0 && (
          <p className='text-muted'>No files have been uploaded</p>
        )}
        {docInfoIns.length !== 0 && (
          <DocumentsTable arr={docInfoIns} name='Insurance' />
        )}
        {docInfoLic.length !== 0 && (
          <DocumentsTable arr={docInfoLic} name='License' />
        )}
      </Col>
    </Row>
  )
}

export default DocumentTable
