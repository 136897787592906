import React, { useState, useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"
import {
  Col,
  Row,
  Button,
  Modal,
  Form,
  ListGroup,
  Toast,
  CloseButton,
} from "react-bootstrap"
import { Loader } from "../../components"
import ModalSchedule from "./components/ModalSchedule"
import { updateScheduleBusiness } from "../../actions/businessActions"

const BusinessSchedule = () => {
  // *********** work with modal window
  const [showModal, setShowModal] = useState(false)
  const [show, setShow] = useState(false)
  const [openSch, setOpenSch] = useState()
  const [scheduleId, setScheduleId] = useState()
  const [locationSch, setLocationSch] = useState({})
  const handleClose = () => setShowModal(false)
  const handleShow = () => setShowModal(true)
  // *********** check days off
  const [daysOff, setDaysOff] = useState([])
  const defaultH = {
    starthour: "",
    startmin: "",
    starttd: "",
    endhour: "",
    endmin: "",
    endtd: "",
  }
  // *********** for select schedule
  const [shift, setShift] = useState({ defaultH })
  const [isLunch, setIsLunch] = useState(false)
  const [lunch, setLunch] = useState({ defaultH })
  const dispatch = useDispatch()

  // *********** slicer for business information
  const { businessInfo, loading } = useSelector((state) => state.businessLogin)

  /**
   * Open modal schedule handler
   */
  const openHandle = (id) => {
    const schedule = businessInfo.schedule.find((x) => x._id === id)
    if (schedule.daysoff !== undefined) {
      schedule.daysoff.daysOff.map((s) =>
        setDaysOff((oldArray) => [...oldArray, s])
      )
    }
    if (schedule.start) {
      setShift((prev) => ({
        ...prev,
        starthour: schedule.start.hours,
        startmin: schedule.start.minutes,
        starttd: schedule.start.td,
      }))
    } else {
      setShift((prev) => ({
        ...prev,
        starthour: "0",
        startmin: "00",
        starttd: "AM",
      }))
    }
    if (schedule.end) {
      setShift((prev) => ({
        ...prev,
        endhour: schedule.end.hours,
        endmin: schedule.end.minutes,
        endtd: schedule.end.td,
      }))
    } else {
      setShift((prev) => ({
        ...prev,
        endhour: "0",
        endmin: "00",
        endtd: "AM",
      }))
    }

    if (schedule.lunch.start.hours !== "0") {
      setIsLunch(true)
      if (schedule.lunch.end) {
        setLunch((prev) => ({
          ...prev,
          endhour: schedule.lunch.end.hours,
          endmin: schedule.lunch.end.minutes,
          endtd: schedule.lunch.end.td,
        }))
      } else {
        setLunch((prev) => ({
          ...prev,
          endhour: "0",
          endmin: "00",
          endtd: "AM",
        }))
      }
      if (schedule.lunch.start) {
        setLunch((prev) => ({
          ...prev,
          starthour: schedule.lunch.start.hours,
          startmin: schedule.lunch.start.minutes,
          starttd: schedule.lunch.start.td,
        }))
      } else {
        setLunch((prev) => ({
          ...prev,
          starthour: "0",
          startmin: "00",
          starttd: "AM",
        }))
      }
    } else {
      setIsLunch(false)
    }

    setScheduleId(id)
    setOpenSch(schedule)
    setLocationSch(schedule.location)
    handleShow()
  }

  /**
   * Close handler
   */
  const handlerClose = () => {
    setShift(defaultH)
    setLunch(defaultH)
    setDaysOff([])
    setScheduleId()
    setOpenSch()
    setLocationSch()
    setIsLunch(false)
    handleClose()
  }
  /**
   * Update schedule handler
   */
  const modalScheduleHandler = (newSchedule) => {
    dispatch(updateScheduleBusiness(businessInfo._id, newSchedule))
    setShift(defaultH)
    setLunch(defaultH)
    setDaysOff([])
    setScheduleId()
    setOpenSch()
    setLocationSch()
    setIsLunch(false)
    handleClose()
  }

  return (
    <>
      {/* *******Main Content******* */}
      <Row>
        <h6 className='text-black mb-3'>My Schedule</h6>
      </Row>
      {businessInfo ? (
        <Row>
          {loading && <Loader />}
          {businessInfo.schedule.map((s, index) => (
            <Col
              key={index}
              lg={5}
              md={5}
              xs={12}
              className='border rounded mx-3 my-3'
            >
              <div className='py-3'>
                <i
                  className='bi bi-geo-alt-fill me-2'
                  style={{ color: "orange" }}
                ></i>
                <strong style={{ fontSize: "0.85rem" }}>
                  {s.location.address}
                </strong>
              </div>
              <hr />
              {s.start && (
                <div>
                  <span>{`Open: ${s.start.hours}:${s.start.minutes} ${s.start.td}`}</span>
                </div>
              )}
              {s.end && (
                <div>
                  <span>{`Close: ${s.end.hours}:${s.end.minutes} ${s.end.td}`}</span>
                </div>
              )}
              {s.lunch.start.hours !== "0" && (
                <div>
                  <span>{`Lunch: ${s.lunch.start.hours}:${
                    s.lunch.start.minutes ? s.lunch.start.minutes : "00"
                  } ${s.lunch.start.td} to ${s.lunch.end.hours}:${
                    s.lunch.end.minutes
                  } ${s.lunch.end.td}`}</span>
                </div>
              )}
              <hr />
              {s.daysoff !== undefined && (
                <>
                  <div>
                    <p>
                      <strong>Days Off</strong>
                    </p>
                    <Row>
                      {s.daysoff.daysOff.map((i, index) => (
                        <Col xs={12} md={6} lg={4} className='my-3' key={index}>
                          <span
                            style={{ fontSize: "0.9rem" }}
                            className='border rounded p-2 me-2'
                          >{`Off ${i} `}</span>
                        </Col>
                      ))}
                    </Row>
                  </div>
                  <hr />
                </>
              )}
              <div className='text-end'>
                <Button
                  type='button'
                  className='shadow p-2 rounded text-end my-2'
                  style={{
                    color: "#415da5",
                    fontWeight: "500",
                    backgroundColor: "white",
                    border: "none",
                    width: "auto",
                  }}
                  onClick={() => openHandle(s._id)}
                >
                  Manage Schedule
                  <i className='bi bi-three-dots-vertical ms-2'></i>
                </Button>
              </div>
            </Col>
          ))}
        </Row>
      ) : (
        ""
      )}
      {/* *******Modal window ******* */}
      {openSch !== undefined ? (
        <ModalSchedule
          showModal={showModal}
          handlerClose={handlerClose}
          openSch={openSch}
          modalScheduleHandler={modalScheduleHandler}
        />
      ) : (
        ""
      )}
    </>
  )
}

export default BusinessSchedule
