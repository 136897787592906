import React from "react"
import { Row, Col, Stack, Button } from "react-bootstrap"
import { hoursHelper } from "../Utils"
import { MapBD } from "./index"

const BusinessDetailsSchedule = ({
  disableButton,
  handlAddr,
  locationSer,
  idLocation,
  services,
  googleKey,
  defaultMap,
  service,
  scheduleHandler,
}) => {
  return (
    <Row className='m-0 p-0 h-100'>
      <Col>
        <Row className='my-3 m-0 p-0 w-100'>
          <Stack direction='horizontal' className='w-100'>
            <Col
              as='button'
              disabled={disableButton.left}
              className='text-center border-0 bg-white my-0 p-0 ms-0 me-2'
              onClick={() => handlAddr("left")}
            >
              <i className='fa-solid fa-angle-left'></i>
            </Col>
            {locationSer.length > 0 && (
              <span className='resp-par-text'>
                {locationSer[idLocation].address}
              </span>
            )}
            <Col
              as='button'
              className='text-center  border-0 bg-white m-0 p-0'
              disabled={disableButton.right}
              onClick={() => handlAddr("right")}
            >
              <i className='fa-solid fa-angle-right'></i>
            </Col>
          </Stack>
        </Row>
        <hr className='m-0' />
        <Row className='my-2 '>
          <Col>
            <div className='h-100 d-flex flex-column my-1 gap-1'>
              <Row className='schedule-hours-preview ms-3'>
                <Col xl={10} md={6} xs={12}>
                  {locationSer.length > 0 &&
                    hoursHelper(locationSer[idLocation].address, services)}
                </Col>
              </Row>
              <Row className='my-2'>
                <Col>
                  <MapBD
                    googleKey={googleKey}
                    defaultMap={defaultMap}
                    service={service}
                  />
                </Col>
              </Row>
              <Row className='d-flex justify-content-center my-2'>
                <Col xs={12} md={4} xl={8}>
                  <Button
                    variant='dark'
                    onClick={scheduleHandler}
                    className='mt-auto bg-gray text-white w-100'
                  >
                    Schedule an Appointment
                    <i className='bi bi-alarm-fill ms-2'></i>
                  </Button>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      </Col>
    </Row>
  )
}

export default BusinessDetailsSchedule

//
