export const welcomeBusinessMessage = () => {
  const welcomeHtml = `<!DOCTYPE html PUBLIC "-//W3C//DTD XHTML 1.0 Transitional//EN" "http://www.w3.org/TR/xhtml1/DTD/xhtml1-transitional.dtd">
      <html
        xmlns="http://www.w3.org/1999/xhtml"
        xmlns:o="urn:schemas-microsoft-com:office:office"
        style="
          width: 100%;
          -webkit-text-size-adjust: 100%;
          -ms-text-size-adjust: 100%;
          padding: 0;
          margin: 0;
        "
      >
        <head>
          <meta charset="UTF-8" />
          <meta content="width=device-width, initial-scale=1" name="viewport" />
          <meta name="x-apple-disable-message-reformatting" />
          <meta http-equiv="X-UA-Compatible" content="IE=edge" />
          <meta content="telephone=no" name="format-detection" />
          <title>Welcome to DooMoble!</title>
          <!--[if (mso 16)]>
            <style type="text/css">
              a {
                text-decoration: none;
              }
            </style>
          <![endif]-->
          <!--[if gte mso 9
            ]><style>
              sup {
                font-size: 100% !important;
              }
            </style><!
          [endif]-->
          <!--[if gte mso 9]>
            <xml>
              <o:OfficeDocumentSettings>
                <o:AllowPNG></o:AllowPNG>
                <o:PixelsPerInch>96</o:PixelsPerInch>
              </o:OfficeDocumentSettings>
            </xml>
          <![endif]-->
          <!--[if !mso]><!-- -->
          <link
            href="https://fonts.googleapis.com/css?family=Lato:400,400i,700,700i"
            rel="stylesheet"
          />
          <!--<![endif]-->
        </head>
      
        <body
          bgcolor="#f4f4f4"
          style="padding: 0; margin: 0"
          height="100%"
          width="100%"
        >
          <div style="background-color: #f4f4f4">
            <!-- <center> -->
            <table
              align="center"
              bgcolor="#f4f4f4"
              border="0"
              cellpadding="0"
              cellspacing="0"
              height="100%"
              width="100%"
              style="mso-table-lspace: 0pt; mso-table-rspace: 0pt"
            >
              <tr style="border-collapse: collapse">
                <td>
                  <table
                    align="center"
                    border="0"
                    cellpadding="0"
                    cellspacing="0"
                    height="100%"
                    width="100%"
                    style="mso-table-lspace: 0pt; mso-table-rspace: 0pt"
                  >
                    <tr style="border-collapse: collapse">
                      <td align="center" valign="top">
                        <table
                          align="center"
                          border="0"
                          bgcolor="#000"
                          cellpadding="0"
                          cellspacing="0"
                          width="100%"
                          style="
                            border: 0;
                            max-width: 600px;
                            background-color: #000;
                            mso-table-lspace: 0pt;
                            mso-table-rspace: 0pt;
                          "
                        >
                          <tbody>
                            <tr style="border-collapse: collapse">
                              <td align="center" valign="top">
                                <a
                                  href="https://doomoble.com"
                                  style="text-decoration: none"
                                  target="_blank"
                                  ><img
                                    alt="Doomoble"
                                    src="https://res.cloudinary.com/a-ray-vision/image/upload/v1660189223/Logos/FullNameLogo_fa2dkb_l3hlid.png"
                                    width="100%"
                                    style="
                                      border: 0;
                                      height: auto;
                                      padding: 0;
                                      margin: 0;
                                      max-width: 600px;
                                      outline: none;
                                      text-align: center;
                                      text-decoration: none;
                                      -ms-interpolation-mode: bicubic;
                                    "
                                    data-bit="iit"
                                /></a>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </td>
                    </tr>
                    <tr style="border-collapse: collapse">
                      <td align="center" valign="top">
                        <table
                          align="center"
                          border="0"
                          bgcolor="#fff"
                          cellpadding="0"
                          cellspacing="0"
                          width="100%"
                          style="
                            max-width: 600px;
                            background-color: #fff;
                            mso-table-lspace: 0pt;
                            mso-table-rspace: 0pt;
                          "
                        >
                          <tbody>
                            <tr style="border-collapse: collapse">
                              <td align="center" valign="top">
                                <table
                                  align="center"
                                  border="0"
                                  bgcolor="#ffa73b"
                                  cellpadding="0"
                                  cellspacing="0"
                                  width="100%"
                                  style="
                                    max-width: 600px;
                                    background-color: #ffa73b;
                                    mso-table-lspace: 0pt;
                                    mso-table-rspace: 0pt;
                                  "
                                >
                                  <tbody>
                                    <tr style="border-collapse: collapse">
                                      <td
                                        align="center"
                                        style="
                                          margin: 0;
                                          padding-bottom: 35px;
                                          padding-left: 30px;
                                          padding-right: 30px;
                                          padding-top: 35px;
                                        "
                                      >
                                        <h1
                                          style="
                                            margin: 0;
                                            line-height: 58px;
                                            mso-line-height-rule: exactly;
                                            font-family: lato, 'helvetica neue',
                                              helvetica, arial, sans-serif;
                                            font-size: 48px;
                                            font-style: normal;
                                            font-weight: normal;
                                            color: #111111;
                                          "
                                        >
                                          Welcome!
                                        </h1>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </td>
                            </tr>
                            <tr style="border-collapse: collapse">
                              <td align="center" valign="top">
                                <tbody>
                                  <table
                                    align="center"
                                    border="0"
                                    bgcolor="#fff"
                                    cellpadding="0"
                                    cellspacing="0"
                                    width="100%"
                                    role="presentation"
                                    style="
                                      max-width: 600px;
                                      background-color: #fff;
                                      mso-table-lspace: 0pt;
                                      mso-table-rspace: 0pt;
                                    "
                                  >
                                    <tbody>
                                      <tr style="border-collapse: collapse">
                                        <td
                                          bgcolor="#ffffff"
                                          align="left"
                                          style="
                                            margin: 0;
                                            padding-top: 20px;
                                            padding-bottom: 20px;
                                            padding-left: 30px;
                                            padding-right: 30px;
                                          "
                                        >
                                          <p
                                            style="
                                              margin: 0;
                                              -webkit-text-size-adjust: none;
                                              -ms-text-size-adjust: none;
                                              mso-line-height-rule: exactly;
                                              font-family: lato, 'helvetica neue',
                                                helvetica, arial, sans-serif;
                                              line-height: 27px;
                                              color: #666666;
                                              font-size: 18px;
                                            "
                                          >
                                          We're excited to have you apart of the DooMoble family. Lets get your business profile completed and published so you can start booking clients! To get started just press the button below.&nbsp;
                                          </p>
                                        </td>
                                      </tr>
                                      <tr style="border-collapse: collapse">
                                        <td
                                          align="center"
                                          style="
                                            margin: 0;
                                            padding-left: 10px;
                                            padding-right: 10px;
                                            padding-top: 35px;
                                            padding-bottom: 35px;
                                          "
                                        >
                                          <span
                                            style="
                                              border-style: solid;
                                              border-color: #ffa73b;
                                              background: #ffa73b;
                                              border-width: 1px;
                                              display: inline-block;
                                              border-radius: 2px;
                                              width: auto;
                                            "
                                            ><a
                                              href="https://doomoble.com/signin/business"
                                              target="_blank"
                                              style="
                                                mso-style-priority: 100 !important;
                                                text-decoration: none;
                                                -webkit-text-size-adjust: none;
                                                -ms-text-size-adjust: none;
                                                mso-line-height-rule: exactly;
                                                color: #ffffff;
                                                font-size: 20px;
                                                border-style: solid;
                                                border-color: #ffa73b;
                                                border-width: 15px 30px;
                                                display: inline-block;
                                                background: #ffa73b;
                                                border-radius: 2px;
                                                font-family: helvetica,
                                                  'helvetica neue', arial, verdana,
                                                  sans-serif;
                                                font-weight: normal;
                                                font-style: normal;
                                                line-height: 24px;
                                                width: auto;
                                                text-align: center;
                                              "
                                              >Login to Dashboard</a
                                            ></span
                                          >
                                        </td>
                                      </tr>
                                      <tr style="border-collapse: collapse">
                                        <td
                                          align="left"
                                          style="
                                            padding: 0;
                                            margin: 0;
                                            padding-top: 20px;
                                            padding-left: 30px;
                                            padding-right: 30px;
                                          "
                                        >
                                          <p
                                            style="
                                              margin: 0;
                                              -webkit-text-size-adjust: none;
                                              -ms-text-size-adjust: none;
                                              mso-line-height-rule: exactly;
                                              font-family: lato, 'helvetica neue',
                                                helvetica, arial, sans-serif;
                                              line-height: 27px;
                                              color: #666666;
                                              font-size: 18px;
                                            "
                                          >
                                            If that doesn't work, copy and paste the
                                            following link in your browser:
                                          </p>
                                        </td>
                                      </tr>
                                      <tr style="border-collapse: collapse">
                                        <td
                                          align="center"
                                          style="
                                            padding: 0;
                                            margin: 0;
                                            padding-top: 20px;
                                            padding-left: 30px;
                                            padding-right: 30px;
                                          "
                                        >
                                          <a
                                            target="_blank"
                                            href="https://doomoble.com/signin/business"
                                            style="
                                              -webkit-text-size-adjust: none;
                                              -ms-text-size-adjust: none;
                                              mso-line-height-rule: exactly;
                                              text-decoration: underline;
                                              color: #ffa73b;
                                              font-size: 18px;
                                            "
                                            >www.DooMoble.com/signin/business</a
                                          >
                                        </td>
                                      </tr>
                                      <tr style="border-collapse: collapse">
                                        <td
                                          align="left"
                                          style="
                                            padding: 0;
                                            margin: 0;
                                            padding-top: 20px;
                                            padding-left: 30px;
                                            padding-right: 30px;
                                          "
                                        >
                                          <p
                                            style="
                                              margin: 0;
                                              -webkit-text-size-adjust: none;
                                              -ms-text-size-adjust: none;
                                              mso-line-height-rule: exactly;
                                              font-family: lato, 'helvetica neue',
                                                helvetica, arial, sans-serif;
                                              line-height: 27px;
                                              color: #666666;
                                              font-size: 18px;
                                            "
                                          >
                                            If you have any questions, just reply to
                                            this email—we're always happy to help out.
                                          </p>
                                        </td>
                                      </tr>
                                      <tr style="border-collapse: collapse">
                                        <td
                                          align="left"
                                          style="
                                            margin: 0;
                                            padding-top: 20px;
                                            padding-left: 30px;
                                            padding-right: 30px;
                                            padding-bottom: 40px;
                                          "
                                        >
                                          <p
                                            style="
                                              margin: 0;
                                              -webkit-text-size-adjust: none;
                                              -ms-text-size-adjust: none;
                                              mso-line-height-rule: exactly;
                                              font-family: lato, 'helvetica neue',
                                                helvetica, arial, sans-serif;
                                              line-height: 27px;
                                              color: #666666;
                                              font-size: 18px;
                                            "
                                          >
                                            Cheers,
                                          </p>
                                          <p
                                            style="
                                              margin: 0;
                                              -webkit-text-size-adjust: none;
                                              -ms-text-size-adjust: none;
                                              mso-line-height-rule: exactly;
                                              font-family: lato, 'helvetica neue',
                                                helvetica, arial, sans-serif;
                                              line-height: 27px;
                                              color: #666666;
                                              font-size: 18px;
                                            "
                                          >
                                            The DooMoble Team
                                          </p>
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </tbody>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </td>
                    </tr>
                    <tr bgcolor="#f4f4f4" style="border-collapse: collapse">
                      <td align="center" valign="top">
                        <table
                          align="center"
                          border="0"
                          bgcolor="#000"
                          cellpadding="0"
                          cellspacing="0"
                          width="100%"
                          style="
                            border: 0;
                            max-width: 600px;
                            background-color: #000;
                            mso-table-lspace: 0pt;
                            mso-table-rspace: 0pt;
                          "
                        >
                          <tbody>
                            <tr style="border-collapse: collapse">
                              <td align="center" valign="top">
                                <a
                                  target="_blank"
                                  href="https://doomoble.com"
                                  style="text-decoration: none"
                                  ><img
                                    src="https://res.cloudinary.com/a-ray-vision/image/upload/v1661343837/Email/EmailLowLogo_oil9v6.jpg"
                                    alt="DooMoble Logo"
                                    style="
                                      border: 0;
                                      height: auto;
                                      padding: 0;
                                      margin: 0;
                                      max-width: 600px;
                                      outline: none;
                                      text-align: center;
                                      text-decoration: none;
                                      -ms-interpolation-mode: bicubic;
                                    "
                                    data-bit="iit"
                                    title="DooMoble Logo"
                                /></a>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </td>
                    </tr>
                  </table>
                </td>
              </tr>
              <!-- <tbody> -->
      
              <!-- </tbody> -->
            </table>
            <!-- </center> -->
          </div>
        </body>
      </html>
      `
  return welcomeHtml
}
