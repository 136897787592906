import React from "react"
import { useLocation } from "react-router-dom"
import { Stack } from "react-bootstrap"
import { DbUser } from "../components"
import { DbBusinesses } from "../components"

const DashBoradScreen = () => {
  const urlLocation = useLocation()
  const typeOfUser = urlLocation.pathname.split("/")[2]
  //slice userinfo

  return (
    <Stack direction='vertical' className='bg-white text-black '>
      {typeOfUser === "user" ? <DbUser /> : <DbBusinesses />}
    </Stack>
  )
}

export default DashBoradScreen
