import React, { useCallback, useState } from "react";
import { Form } from "react-bootstrap";
import AddBusinessFormView from "./AddBusinessFormView";
import { useSelector } from "react-redux";

const AddBusinessCategoryView = ({
  onCategoryChange,
  categoryError,
  onOtherCategoryChange,
  otherCategoryError,
}) => {
  const [isOtherCategory, setIsOtherCategory] = useState(false);
  const { settingsInfo } = useSelector((state) => state.settingsUp);

  const getCategories = useCallback(() => {
    const mainServices = settingsInfo?.[0]?.mainServices || [];
    const usersServices = settingsInfo?.[0]?.usersServices || [];

    return [
      ...mainServices.slice(1).map((item) => ({
        shortName: item?.shortName,
        name: item?.name,
      })),
      ...usersServices.map((item) => ({
        shortName: item?.otherName || item?.shortName,
        name: item?.name,
      })),
    ];
  }, [settingsInfo]);
  return (
    <>
      <Form.Group className="my-3">
        <Form.Label className="sgnup-form-label">Select a Category</Form.Label>
        <Form.Select
          onChange={(e) => {
            const value = e.target.value;
            const splittedVal = value.split("@");
            const categoryValue = splittedVal?.[1] || "";
            const categoryName = splittedVal?.[0] || "";

            onCategoryChange(categoryValue.trim(), categoryName.trim());

            if (value === "@custom-other") {
              setIsOtherCategory(true);
            } else {
              setIsOtherCategory(false);
            }
          }}
          isInvalid={categoryError}
        >
          <option value="">Select Category</option>
          {getCategories().map((item) => (
            <option value={`${item.name}@${item?.shortName || "other"}`}>
              {item?.name}
            </option>
          ))}
          <option value="@custom-other">Other</option>
        </Form.Select>
        <Form.Control.Feedback type="invalid">
          {categoryError}
        </Form.Control.Feedback>
      </Form.Group>
      {isOtherCategory && (
        <AddBusinessFormView
          id="Other Category"
          label="Other Category"
          type="text"
          onChange={onOtherCategoryChange}
          error={otherCategoryError}
        />
      )}
    </>
  );
};

export default AddBusinessCategoryView;
