import { useEffect, useState } from "react"
import { Modal, Button, Alert } from "react-bootstrap"
import { toast } from "react-toastify"
import LunchView from "./Lunch/views/LunchView"
import ShiftView from "./Shift/views/ShiftView"
import {
  addStartHour,
  addStartMinutes,
  addStartTd,
  addEndHour,
  addEndMinutes,
  addEndTd,
  addIsLunch,
  getLunch,
  setLunch,
  checkLunch,
} from "./Lunch/model/Lunch"
import {
  addShiftStartHour,
  addShiftStartMinutes,
  addShiftStartTd,
  addShiftEndHour,
  addShiftEndMinutes,
  addShiftEndTd,
  getShift,
  setShift,
  checkShift,
} from "./Shift/model/Shift"

import ModalScheduleDO from "./ModalScheduleDO"

const ModalSchedule = ({
  showModal,
  handlerClose,
  openSch,
  modalScheduleHandler,
}) => {
  const [isLunch, setIsLunch] = useState(true)
  const [isLunchError, setIsLunchError] = useState(false)
  const [isShiftError, setIsShiftError] = useState(false)

  /**
   * Alert functionality
   */
  const [isAlert, setIsAlert] = useState(false)

  /**
   * Main schedule state
   */
  const [schedule, setSchedule] = useState({
    location: { address: "" },
    daysoff: { daysOff: [] },
  })

  useEffect(() => {
    if (openSch !== undefined) {
      setSchedule(openSch)
      if (openSch.lunch.start.hours !== "0") {
        setLunch(openSch.lunch)
        setIsLunch(true)
      } else {
        setIsLunch(false)
        // setLunch(false)
      }
      setShift({ start: openSch.start, end: openSch.end })
    }
  }, [openSch])

  /**
   * Schedule handler
   */
  const setScheduleHandler = () => {
    if (!checkLunch()) {
      setIsLunchError(true)
    } else {
      setIsLunchError(false)
    }

    if (!checkShift()) {
      setIsShiftError(true)
    } else {
      setIsShiftError(false)
    }
    if (checkLunch() && checkShift()) {
      setIsAlert(true)
      modalScheduleHandler(schedule)
      setIsLunchError(false)
      setIsShiftError(false)
      toast.success(" Your schedule has been updated.")
    }
  }

  /**
   * Shift handlers
   */
  const setStartShift = () => {
    const tempShift = getShift()

    setSchedule((prev) => ({
      ...prev,
      start: tempShift.start,
    }))
  }
  const setEndShift = () => {
    const tempShift = getShift()
    setSchedule((prev) => ({
      ...prev,
      end: tempShift.end,
    }))
  }
  const startShiftHoursHandler = (hour) => {
    addShiftStartHour(hour)
    setStartShift()
  }
  const startShiftMinutesHandler = (min) => {
    addShiftStartMinutes(min)
    setStartShift()
  }
  const startShiftTdHandler = (td) => {
    addShiftStartTd(td)
    setStartShift()
  }
  const endShiftHoursHandler = (hour) => {
    addShiftEndHour(hour)
    setEndShift()
  }
  const endShiftMinutesHandler = (min) => {
    addShiftEndMinutes(min)
    setEndShift()
  }
  const endShiftTdHandler = (td) => {
    addShiftEndTd(td)
    setEndShift()
  }

  /**
   * Lunch handlers
   */
  const setLunchInSchedule = () => {
    setSchedule((prev) => ({
      ...prev,
      lunch: getLunch(),
    }))
  }
  const startHoursHandler = (hour) => {
    addStartHour(hour)
    setLunchInSchedule()
  }
  const startMinutesHandler = (min) => {
    addStartMinutes(min)
    setLunchInSchedule()
  }
  const startTdHandler = (td) => {
    addStartTd(td)
    setLunchInSchedule()
  }
  const endHoursHandler = (hour) => {
    addEndHour(hour)
    setLunchInSchedule()
  }
  const endMinutesHandler = (min) => {
    addEndMinutes(min)
    setLunchInSchedule()
  }
  const endTdHandler = (td) => {
    addEndTd(td)
    setLunchInSchedule()
  }
  const isLunchHandler = (vol) => {
    addIsLunch(vol)
    setIsLunch(vol)
    setLunchInSchedule()
  }

  /**
   * Main return
   */
  return (
    <Modal
      show={showModal}
      onHide={handlerClose}
      backdrop='static'
      keyboard={false}
      centered
      size='lg'
    >
      <Modal.Header closeButton>
        <Modal.Title>Set Schedule</Modal.Title>
      </Modal.Header>
      <Modal.Body className='m-0 p-0'>
        <div className='px-3 py-2'>
          <i
            className='bi bi-geo-alt-fill me-2'
            style={{ color: "orange" }}
          ></i>
          {schedule.location.address}
        </div>
        <hr className='mb-0' />
        {/* Shift View */}
        {schedule.start !== undefined && (
          <ShiftView
            shift={getShift()}
            isShiftError={isShiftError}
            startHoursHandler={startShiftHoursHandler}
            startMinutesHandler={startShiftMinutesHandler}
            startTdHandler={startShiftTdHandler}
            endHoursHandler={endShiftHoursHandler}
            endMinutesHandler={endShiftMinutesHandler}
            endTdHandler={endShiftTdHandler}
          />
        )}

        <hr className='mt-0' />
        {/* Lunch View */}
        {schedule.lunch !== undefined && (
          <LunchView
            lunch={getLunch()}
            isLunchError={isLunchError}
            isLunch={isLunch}
            setIsLunch={isLunchHandler}
            startHoursHandler={startHoursHandler}
            startMinutesHandler={startMinutesHandler}
            startTdHandler={startTdHandler}
            endHoursHandler={endHoursHandler}
            endMinutesHandler={endMinutesHandler}
            endTdHandler={endTdHandler}
          />
        )}

        <hr className='m-0' />
        <ModalScheduleDO daysoFF={schedule.daysoff} setSchedule={setSchedule} />
        <hr className='mt-0' />
      </Modal.Body>

      <Modal.Footer as='div' className='mt-auto d-flex justify-content-start'>
        <Button
          type='button'
          className='shadow p-2 rounded'
          style={{
            color: "#3b8543",
            fontWeight: "bold",
            backgroundColor: "white",
            border: "none",
            width: "auto",
          }}
          onClick={setScheduleHandler}
        >
          Save Schedule
          <i className='fa-solid fa-floppy-disk ms-2'></i>
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default ModalSchedule
