import React from "react"
import { Row, Col } from "react-bootstrap"
import { BusinessPreviewCarusel, BusinessPreviewSchedule } from "./index"

const BusinessPrreviewSecondSection = ({
  gallery,
  disableButton,
  handlAddr,
  locationSer,
  idLocation,
  services,
  service,
  defaultMap,
  googleKey,
}) => {
  return (
    <Row className='mx-0 p-0 mt-5'>
      <Col xl={8} md={12} xs={12}>
        <BusinessPreviewCarusel gallery={gallery} />
      </Col>

      <Col xl={4} md={12} xs={12} className='border my-4 my-xl-0 px-0'>
        <BusinessPreviewSchedule
          disableButton={disableButton}
          handlAddr={handlAddr}
          locationSer={locationSer}
          idLocation={idLocation}
          services={services}
          googleKey={googleKey}
          defaultMap={defaultMap}
          service={service}
        />
      </Col>
    </Row>
  )
}

export default BusinessPrreviewSecondSection
