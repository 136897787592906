import React from "react"
import { Row, Col } from "react-bootstrap"
import ShiftFormSelect from "./ShiftFormSelect"
import { hours, min, td } from "../model/shiftData"

const ShiftForm = ({
  name,
  hourV,
  minV,
  tdV,
  isShiftError,
  hoursHandler,
  minutesHandler,
  tdHandler,
}) => {
  return (
    <Col lg={6} md={5} xs={12} className='border-end m-0 p-3 '>
      <Row>
        <span className={isShiftError ? "text-danger" : ""}>{name}</span>
        <ShiftFormSelect
          val={hourV}
          err={isShiftError}
          arr={hours}
          handler={hoursHandler}
        />
        <ShiftFormSelect
          val={minV}
          err={isShiftError}
          arr={min}
          handler={minutesHandler}
        />
        <ShiftFormSelect
          val={tdV}
          err={isShiftError}
          arr={td}
          handler={tdHandler}
        />
      </Row>
    </Col>
  )
}

export default ShiftForm
