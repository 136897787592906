import React, { useEffect, useState } from "react"
import { useSelector, useDispatch } from "react-redux"
import ApnAccordion from "../businessDashboard/components/ApnAccordion"
import { getBusinessApnById } from "../../actions"

const BusinessAppointments = ({ businessInfo }) => {
  const [aptInfo, setAptInfo] = useState()
  const [bisInfo, setBisInfo] = useState()

  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(getBusinessApnById(businessInfo.appointments))
  }, [businessInfo])

  const { businessApnInfo } = useSelector((state) => state.getBusinessApnById)
  useEffect(() => {
    if (businessApnInfo !== undefined) {
      setAptInfo(businessApnInfo)
      setBisInfo(businessInfo)
    }
  }, [businessApnInfo])

  return <ApnAccordion businessInfo={bisInfo} businessApnInfo={aptInfo} />
}

export default BusinessAppointments
