import { useState, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import {
  UserInfoBD,
  BusinessInfoBD,
  LocationBD,
  ServicesBD,
  DateTimeBD,
} from "./modal"

import { Modal, Button, Row, Col } from "react-bootstrap"
import { ButtonShadow } from "../../../components"
import HeaderTitle from "./modal/view/HeaderTitle"
import { bdstr } from "../../strings"

import {
  ModalTitle,
  BusinessInfo,
  UserInfo,
  Location,
  Services,
  DateTime,
} from "../components/modal"
import { bamstr } from "../../strings"
import { acceptBusinessApn, cancelAppointmentById } from "../../../actions"

const ModalApn = ({
  showModal,
  appointmentInfo,
  handleCloseModal,
  userInfo,
  businessInfo,
  acceptAppointmentHandler,
  cancelAppointmentHandler,
  completedAppointmentHandler,
}) => {
  const [content, setContent] = useState()
  const [isLoading, setIsLoading] = useState(true)
  const [isEditApp, setIsEditApp] = useState(false)

  const dispatch = useDispatch()

  const [apptServices, setApptServices] = useState(
    appointmentInfo.services.map((s) => s)
  )
  const accAppt = () => {
    acceptAppointmentHandler(appointmentInfo._id)
  }
  const cancelAppt = () => {
    cancelAppointmentHandler(appointmentInfo._id)
    // acceptAppointmentHandler(appointmentInfo._id)
  }
  const completeAppt = () => {
    completedAppointmentHandler(appointmentInfo._id)
    // acceptAppointmentHandler(appointmentInfo._id)
  }

  return (
    <>
      <Modal
        show={showModal}
        onHide={handleCloseModal}
        backdrop='static'
        centered
        size='xl'
        className='modal-dialog-scrollable text-black'
        style={{ height: "auto" }}
      >
        {/* *******Header******* */}
        <Modal.Header closeButton className='btn-close-white border-0'>
          <Modal.Title as='h6' style={{ color: "#ffffff" }}>
            Schedule an Appointment
          </Modal.Title>
        </Modal.Header>
        <HeaderTitle
          setIsEditApp={setIsEditApp}
          isEditApp={isEditApp}
          status={appointmentInfo.userstatus}
          accAppt={accAppt}
          cancelAppt={cancelAppt}
          complAppt={completeAppt}
        />

        {/* *******Body******* */}

        <Modal.Body className='text-black d-flex flex-column min-vh-100'>
          <>
            <UserInfoBD
              avatar={userInfo.avatar.path}
              name={userInfo.name}
              member={userInfo.joined}
            />
            <hr className='border' />

            <BusinessInfoBD
              avatar={businessInfo.avatar.path}
              name={businessInfo.name}
              rating={businessInfo.rating}
            />
            <hr className='border' />
            <LocationBD location={appointmentInfo.location} />
            <hr className='border' />
            <ServicesBD services={appointmentInfo.services} />
            <hr className='border' />
            <DateTimeBD datetime={appointmentInfo.datetime} />

            <hr className='border' />
          </>
        </Modal.Body>
      </Modal>
    </>
  )
}

export default ModalApn
