import React, { useEffect, useState } from "react"
import GoogleMapReact from "google-map-react"

const AnPoint = ({ center, service }) => (
  <div
    lat={center.lat}
    lng={center.lng}
    className='d-flex flex-row'
    style={{
      color: "orange",
      fontSize: "1rem",
      fontWeight: "600",
    }}
  >
    <i className='bi bi-geo-alt-fill '></i>
    {service !== undefined ? service.businessName : ""}
  </div>
)
const MapBD = ({ googleKey, defaultMap, service }) => {
  const [center, setCenter] = useState()

  useEffect(() => {
    if (defaultMap !== undefined) {
      setCenter(defaultMap)
    }
  }, [defaultMap])

  return (
    <div className='bordered p-1' style={{ height: "15rem" }}>
      {center !== undefined && (
        <GoogleMapReact
          bootstrapURLKeys={{
            key: googleKey,
            v: "3.30",
          }}
          center={center.center}
          defaultZoom={center.zoom}
        >
          <AnPoint center={(center, service)} />
          {/* <div
            lat={center.lat}
            lng={center.lng}
            className='d-flex flex-row'
            style={{
              color: "orange",
              fontSize: "1rem",
              fontWeight: "600",
            }}
          >
            <i className='bi bi-geo-alt-fill '></i>
            {service !== undefined ? service.businessName : ""}
          </div> */}
        </GoogleMapReact>
      )}
    </div>
  )
}
export default MapBD
