export const getAppointmentReducer = (state = {}, action) => {
  switch (action.type) {
    case "GET_APPOINTMENT_REQUEST":
      return { loading: true }
    case "GET_APPOINTMENT_SUCCESS":
      return { loading: false, appointmentInfo: action.payload }
    case "GET_APPOINTMENT_FAIL":
      return { loading: false, error: action.payload }
    default:
      return state
  }
}
export const getAppointmentsByIdReducer = (state = {}, action) => {
  switch (action.type) {
    case "GET_APPOINTMENTS_BY_ID_REQUEST":
      return { loading: true }
    case "GET_APPOINTMENTS_BY_ID_SUCCESS":
      return { loading: false, apptById: action.payload }
    case "GET_APPOINTMENTS_BY_ID_FAIL":
      return { loading: false, error: action.payload }
    default:
      return state
  }
}
export const leftRatingApptReducer = (state = {}, action) => {
  switch (action.type) {
    case "LEFT_RATING_REQUEST":
      return { loading: true }
    case "LEFT_RATING_SUCCESS":
      return { loading: false, updatedRating: action.payload }
    case "LEFT_RATING_FAIL":
      return { loading: false, error: action.payload }
    default:
      return state
  }
}
export const cancelAppointmentReducer = (state = {}, action) => {
  switch (action.type) {
    case "CANCEL_APPOINTMENT_REQUEST":
      return { loading: true, succes: false }
    case "GET_APPOINTMENT_SUCCESS":
      return { loading: false, appointmentInfo: action.payload }
    case "CANCEL_APPOINTMENT_SUCCESS":
      return { loading: false, succes: true, appointmentInfo: action.payload }
    case "CANCEL_APPOINTMENT_FAIL":
      return { loading: false, error: action.payload, succes: false }
    default:
      return state
  }
}
