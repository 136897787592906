import React from "react"
import { Form } from "react-bootstrap"

const AddBusinessDescription = ({ descriptionError, onDescriptionChange }) => {
  return (
    <Form.Group className='my-3'>
      <Form.Label className='sgnup-form-label'>Business Description</Form.Label>
      <Form.Control
        as='textarea'
        rows={3}
        type='text'
        onChange={(e) => onDescriptionChange(e.target.value)}
        isInvalid={descriptionError}
      />
      <Form.Control.Feedback type='invalid'>
        {descriptionError}
      </Form.Control.Feedback>
    </Form.Group>
  )
}

export default AddBusinessDescription
