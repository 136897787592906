import React from "react"
import { Row } from "react-bootstrap"

const HomeScreenMap = () => {
  return (
    <Row className='m-0 p-0'>
      <div className='maphs-main'></div>
    </Row>
  )
}

export default HomeScreenMap
