import React from "react"
import { Row, Col } from "react-bootstrap"

const AboutUsTop = () => {
  return (
    <Row className='m-0 justify-content-md-center top-main text-center'>
      <Col>
        <h4 className='text-orange mt-5'>About Us</h4>
        <h6 className='text-white mb-5'>
          Everything you need to know about DooMoble
        </h6>
      </Col>
    </Row>
  )
}

export default AboutUsTop
