import React from "react"
import { Container } from "react-bootstrap"

import {
  AboutUsTop,
  AboutUsAddBusiness,
  AboutUsSearch,
} from "../components/aboutUsScreen"

const AboutUsScreen = () => {
  return (
    <Container fluid className='m-0 p-0'>
      <AboutUsTop />
      <AboutUsAddBusiness />
      <AboutUsSearch />
    </Container>
  )
}

export default AboutUsScreen
