import React, { useEffect, useState } from "react";
import { Stack, Container, Spinner, Row, Col } from "react-bootstrap";
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { login } from "../actions";
import LoginView from "../components/loginScreen/views/LoginView";
import { toast } from "react-toastify";

const LoginScreen = () => {
  const dispatch = useDispatch();
  const urlLocation = useLocation();
  const history = useNavigate();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [typeOfUser, setTypeOfUser] = useState("");
  const [errorEmail, setErrorEmail] = useState();
  const [errorPassword, setErrorPassword] = useState();
  const { userInfo } = useSelector((state) => state.userLogin);

  const { loading, error, loginStatus } = useSelector(
    (state) => state.loginPage
  );
  const bi = localStorage.getItem("businessInfo");
  const ui = localStorage.getItem("userInfo");
  /**
   * Sucess login
   */
  // useEffect(() => {
  //   if (bi || ui) {
  //     history(`/dashboard/${typeOfUser}`)
  //   } else {
  //   }
  // }, [])

  useEffect(() => {
    if (userInfo) {
      history(`/dashboard/${typeOfUser}`);
    }
  }, [loginStatus]);
  /**
   * UseEffect inital load component
   */
  useEffect(() => {
    setTypeOfUser(urlLocation.pathname.split("/")[2]);
  }, [urlLocation]);
  /**
   * UseEffect to catch error
   */
  useEffect(() => {
    if (error !== undefined) {
      if (error.errorEmail) {
        setErrorEmail(error.errorEmail);
      }
      if (error.errorPassword) {
        setErrorPassword(error.errorPassword);
      }
      if (error.errorGeneral) {
        toast.error(error.errorGeneral);
      }
    }
  }, [error]);
  console.log(typeOfUser);

  /**
   * Submit form handler
   */
  const submitHandler = () => {
    setErrorEmail("");
    setErrorPassword("");
    dispatch(
      login({ email, password, typeOfUser }, function () {
        history(`/dashboard/${typeOfUser}`);
      })
    );
  };

  return (
    <Container fluid className="bg-white m-0 p-0">
      <Row className="m-0 p-0 d-flex justify-content-center">
        <Col xl={4} md={8} xs={12}>
          {loading ? (
            <Row className="mt-5 text-black text-center">
              <Col>
                <Spinner animation="border" />
              </Col>
            </Row>
          ) : (
            <LoginView
              typeOfUser={typeOfUser}
              email={email}
              password={password}
              setEmail={setEmail}
              setPassword={setPassword}
              errorEmail={errorEmail}
              errorPassword={errorPassword}
              submitHandler={submitHandler}
            />
          )}
        </Col>
      </Row>
    </Container>
  );
};

export default LoginScreen;
