import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { listServices } from "../actions";
import {
  ServiceScreenTop,
  ServiceScreenMainContext,
} from "../components/serviceScreen";
import { getSettings } from "../actions/settingsAction";

const ServicesScreen = () => {
  const [mainServices, setMainServices] = useState();
  /**
   * Get params from url
   */
  const [searchParams, setSearchParams] = useSearchParams();
  const keyword = searchParams.get("keyword");
  const param_category = searchParams.get("category");
  const param_othername = searchParams.get("othername");
  const [keywords, setKeywords] = useState();
  const [category, setCategory] = useState();
  const [othername, setOthername] = useState();
  const settingsInfo = JSON.parse(localStorage.getItem("settingsInfo"));
  const [currentPage, setCurrentPage] = useState(1);
  const [titleDM, setTitleDM] = useState("Recently Added");
  const [totalPages, setTotalPages] = useState(1);

  const onPrev = () => {
    if (currentPage > 1) {
      setCurrentPage((s) => s - 1);
    }
  };
  const onNext = () => {
    const lastPage = services?.totalPages || 1;
    if (currentPage < lastPage) {
      setCurrentPage((s) => s + 1);
    }
  };

  useEffect(() => {
    setMainServices([]);
    // console.log(param_category)
    // if (!param_category) {
    //   dispatch(listServices("", "", "", 1))
    // }
  }, []);

  useEffect(() => {
    setMainServices([]);
    setKeywords(keyword);
    setCategory(param_category);
    setOthername(param_othername);
  }, [keyword, param_category, param_othername]);

  const dispatch = useDispatch();

  useEffect(() => {
    if (keywords) {
      dispatch(listServices(keywords, "", "", currentPage));
    } else if (category) {
      if (category === "other") {
        dispatch(listServices("", "other", othername, currentPage));
      } else if (category !== "other") {
        dispatch(listServices("", category, "", currentPage));
      } else {
        dispatch(listServices("", "", "", currentPage));
      }
    }
  }, [keywords, category, othername, currentPage]);

  const { services } = useSelector((state) => state.serviceList);

  /**
   * Work with filter
   */
  const [listView, setListView] = useState(false);
  const [categoryName, setCategoryName] = useState("");
  const [serviceLenght, setServiceLenght] = useState(0);

  useEffect(() => {
    dispatch(getSettings());
  }, []);

  useEffect(() => {
    if (services !== undefined) {
      if (services?.data?.length > 0) {
        setServiceLenght(services?.data?.length);
        setMainServices(services?.data);
      } else {
        setMainServices();
        setServiceLenght(0);
      }
      setTotalPages(services?.totalPages);
    }
    if (keyword) {
      setCategoryName("All businesses");
    } else {
      if (param_category && mainServices !== undefined) {
        let fullName;
        // const temp = Util()
        if (param_category === undefined) {
          fullName = "Pick a Category";
        } else {
          if (settingsInfo !== undefined) {
            const findedMainCategory = settingsInfo[0].mainServices.find(
              (o) => o.shortName === category
            );
            if (findedMainCategory === undefined) {
              const findedUserCategory = settingsInfo[0].usersServices.find(
                (o) =>
                  o.otherName === othername || o?.shortName === param_othername
              );

              fullName = findedUserCategory?.name;
            } else {
              fullName = findedMainCategory?.name;
            }
          }
        }

        setCategoryName(fullName);
      }
    }
  }, [services]);

  /**
   * Work with sorter
   */
  console.log(mainServices);

  return (
    <Container fluid className="m-0 p-0 bg-white">
      <ServiceScreenTop
        setCategoryName={(val) => {
          setCategoryName(val);
          setCurrentPage(1);
          setTotalPages(1);
        }}
      />
      {mainServices || mainServices?.length > 0 ? (
        <ServiceScreenMainContext
          setListView={setListView}
          serviceLenght={serviceLenght}
          categoryName={categoryName}
          setTitleDM={setTitleDM}
          titleDM={titleDM}
          listView={listView}
          mainServices={mainServices}
          totalPages={totalPages}
          onPrev={onPrev}
          onNext={onNext}
          currentPage={currentPage}
          setPage={(s) => setCurrentPage(s)}
        />
      ) : (
        <div
          style={{
            color: "black",
            paddingBlock: "4rem",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <p>Nothing Found</p>
        </div>
      )}
    </Container>
  );
};

export default ServicesScreen;
