import React from "react"
import { Col, Form } from "react-bootstrap"

const ShiftFormSelect = ({ val, err, arr, handler }) => {
  return (
    <Col className='w-auto '>
      <Form.Group className='mt-3'>
        <Form.Select
          value={val}
          className={err ? "border border-red" : ""}
          onChange={(e) => handler(e.target.value)}
        >
          {arr.map((i, index) => (
            <option key={index} value={i}>
              {i}
            </option>
          ))}
        </Form.Select>
      </Form.Group>
    </Col>
  )
}

export default ShiftFormSelect
