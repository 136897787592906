import React, { useState, useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"

import {
  Image,
  Row,
  Stack,
  Form,
  Button,
  Col,
  DropdownButton,
  Dropdown,
  InputGroup,
  FormControl,
  Alert,
} from "react-bootstrap"
import NumberFormat from "react-number-format"

import DocumentsTable from "./components/DocumentsTable"
import {
  changeAvatarBusiness,
  updatePersonalBusiness,
  updateBusinessBusiness,
} from "../../actions"

const BusinessManager = ({
  business,
  businessInfoDetails,
  addDocumentHandler,
  updateDocumentHandler,
  deleteDocumentHandler,
}) => {
  //slicer for business info
  const { upload } = useSelector((state) => state.uploadDocument)
  const { success } = useSelector((state) => state.deleteDocument)

  //states for files
  const [documents, setDocuments] = useState()
  const [typeDocument, setTypeDocument] = useState("Type")
  const [chooseAlert, setChooseAlert] = useState({})
  const [docInfoIns, setDocInfoIns] = useState([])
  const [docInfoLic, setDocInfoLic] = useState([])

  //is form active state
  const [isEditPersonal, setIsEditPersonal] = useState(true)
  const [isEditBusiness, setIsEditBusiness] = useState(true)
  //states for work with avatar
  const [previewSource, setPreviewSource] = useState("")
  const [fileInputState, setFileInputState] = useState("")

  //state work with document
  const [documentUpload, setDocumentUpload] = useState(false)
  //states for work personal profile form
  const [personalForm, setPersonalForm] = useState({})
  const [businessForm, setBusinessForm] = useState({})

  useEffect(() => {
    setPersonalForm({
      name: business.name,
      email: business.email,
      phone: business.phone,
    })
    business.documents.map((x) => checkType(x))
    setBusinessForm({
      businessName: business.businessName,
      details: business.details,
      experience: business.experience,
    })
  }, [setPersonalForm, business, setBusinessForm])

  const checkType = (arr) => {
    if (arr.type === "Insurance") {
      setDocInfoIns((y) => [...y, arr])
    } else if (arr.type === "License") {
      setDocInfoLic((y) => [...y, arr])
    }
  }

  useEffect(() => {
    let timer = setTimeout(() => {
      setChooseAlert({})
    }, 2000)
    return () => {
      clearTimeout(timer)
    }
  }, [chooseAlert])

  useEffect(() => {
    if (businessInfoDetails) {
      setDocInfoIns([])
      setDocInfoLic([])
      businessInfoDetails.documents.map((x) => checkType(x))
    }
  }, [businessInfoDetails])

  useEffect(() => {
    if (upload) {
      checkType(upload)
    }
  }, [upload])

  useEffect(() => {
    if (success) {
    }
  }, [success])

  const [personalErrors, setPersonalErrors] = useState({})
  const [businessErrors, setBusinessErrors] = useState({})

  //def dispatch
  const dispatch = useDispatch()
  //work with personal form asaSAsdadfddsadas
  const setPersonalField = (field, value) => {
    setPersonalForm({
      ...personalForm,
      [field]: value,
    })

    if (!!personalErrors[field])
      setPersonalErrors({
        ...personalErrors,
        [field]: null,
      })
  }

  //work with business form
  const setBusinessFieldB = (field, value) => {
    setBusinessForm({
      ...businessForm,
      [field]: value,
    })

    if (!!businessErrors[field])
      setBusinessErrors({
        ...businessErrors,
        [field]: null,
      })
  }

  //preview avatar function
  const previewFile = (file) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onloadend = () => {
      setPreviewSource(reader.result)
    }
  }

  //cancel to edit personal information
  const cancelHandler = () => {
    setIsEditPersonal(true)
    personalForm.name = business.name
    personalForm.email = business.email
    personalForm.phone = business.phone

    setPersonalErrors({})
  }
  //cancel to edit business information
  const cancelBusinessHandler = () => {
    setIsEditBusiness(true)
    setBusinessForm({
      name: business.businessName,
      details: business.details,
      experience: business.experience,
    })
    setPersonalErrors({})
  }

  // submit avatar handler
  const handleSubmitFile = (e) => {
    e.preventDefault()
    if (!previewSource) return
    uploadImage(previewSource)
  }
  const uploadImage = (base64EncodedImage) => {
    try {
      dispatch(
        changeAvatarBusiness(
          base64EncodedImage,
          business.avatar.path,
          business._id
        )
      )
      setPreviewSource("")
    } catch (error) {
      console.error(error)
    }
  }

  const handleFileInput = (e) => {
    const file = e.target.files[0]
    previewFile(file)
  }

  //submit personal info
  const submitPersonalFormHandler = (e) => {
    e.preventDefault()
    const formErrors = {}

    if (Object.keys(formErrors).length > 0) {
      setPersonalErrors(formErrors)
    } else {
      // *******Dispatch personal******
      dispatch(
        updatePersonalBusiness(
          personalForm.name,
          personalForm.email,
          personalForm.phone,
          business._id
        )
      )
    }
    setIsEditPersonal(true)
    setPersonalForm({})
  }

  //submit business info
  const submitBusinessFormHandler = (e) => {
    e.preventDefault()
    const formErrors = {}

    if (Object.keys(formErrors).length > 0) {
      setPersonalErrors(formErrors)
    } else {
      dispatch(
        updateBusinessBusiness(
          businessForm.businessName,
          businessForm.details,
          businessForm.experience,
          business._id
        )
      )
    }
    setIsEditBusiness(true)
    setBusinessForm({})
  }

  /** Document functions block */
  const typeAlert = (type) => {
    if (typeDocument === "Type") {
      setChooseAlert({
        variant: "warning",
        text: "Please choose type of document",
      })
    } else {
      if (!document) {
        setChooseAlert({
          variant: "warning",
          text: "Please upload document",
        })
      }
    }
  }
  const createFormData = (file, typeFile) => {
    const tp = file.name.split(".").pop()
    if (tp === "PDF" || tp === "pdf" || tp === "jpg" || tp === "png") {
      setChooseAlert({
        variant: "success",
        text: "File uploaded successfully",
      })
      const formData = new FormData()
      formData.append("file", file)
      formData.append("type", typeFile)
      formData.append("id", business._id)
      return formData
    } else {
      setChooseAlert({
        variant: "danger",
        text: "Please upload only pdf, jpg, png",
      })
    }
  }

  /** Add document handler */
  const handleSubmitDocument = (e) => {
    e.preventDefault()
    typeAlert()
    const fd = createFormData(documents[0], typeDocument)
    if (fd) {
      addDocumentHandler(fd)
      setDocumentUpload(false)
      setTypeDocument("Type")
    }
  }

  /** Reupload document */
  const uplFun = (oldF, newF, typeFile) => {
    typeAlert()
    const fd = createFormData(newF, typeFile)
    if (fd) {
      updateDocumentHandler(fd, oldF, typeFile, business._id)
    }
  }

  /** Delete document */
  const delFun = (file, name) => {
    deleteDocumentHandler(file, business._id)
    if (name === "Insurance") {
      const temp = docInfoIns.filter((x) => x.name !== file)
      if (temp.length === 0) {
        setDocInfoIns([])
      } else {
        setDocInfoIns(temp)
      }
    } else if (name === "License") {
      const temp = docInfoIns.filter((x) => x.name !== file)
      if (temp.length === 0) {
        setDocInfoLic([])
      } else {
        setDocInfoLic(temp)
      }
    }
  }

  /** Main return */
  return (
    <Stack direction='vertical'>
      <Row>
        {
          <Form
            className='d-flex flex-row align-items-center'
            onSubmit={handleSubmitFile}
          >
            <Image
              src={previewSource ? previewSource : business.avatar.path}
              className='avatar-user-manager me-3 shadow '
            />

            {previewSource ? (
              <>
                <Button
                  type='submit'
                  className='shadow p-2 rounded'
                  style={{
                    color: "#3b8543",
                    fontWeight: "bold",
                    backgroundColor: "white",
                    border: "none",
                  }}
                >
                  Save<i className='fa-solid fa-floppy-disk ms-2'></i>
                </Button>
                <Button
                  className='shadow ms-3 p-2 rounded '
                  style={{
                    color: "#d1372d",
                    fontWeight: "bold",
                    backgroundColor: "white",
                    border: "none",
                  }}
                  onClick={() => setPreviewSource("")}
                >
                  Cancel <i className='bi bi-x-lg ms-2'></i>
                </Button>
              </>
            ) : (
              <div className='shadow p-2 rounded btn-text-dashboard-user'>
                <input
                  id='inputImage'
                  type='file'
                  name='image'
                  onChange={handleFileInput}
                  value={fileInputState}
                  style={{ display: "none" }}
                />
                <label htmlFor='inputImage'>
                  Edit Profile Picture
                  <i className='bi bi-pencil-fill ms-2'></i>
                </label>
              </div>
            )}
          </Form>
        }
      </Row>
      {/* *******Personal Information******* */}
      <Row className='my-4'>
        <Col>
          <strong>Personal Info</strong>
        </Col>
        {isEditPersonal ? (
          <Button
            type='submit'
            className='shadow p-2 rounded text-end'
            style={{
              color: "black",
              backgroundColor: "white",
              border: "none",
              width: "auto",
            }}
            onClick={() => setIsEditPersonal(false)}
          >
            Edit<i className='bi bi-pencil-fill ms-2'></i>
          </Button>
        ) : (
          <>
            <Button
              type='submit'
              className='shadow p-2 rounded'
              style={{
                color: "#3b8543",
                fontWeight: "bold",
                backgroundColor: "white",
                border: "none",
                width: "auto",
              }}
              form='personalFormId'
            >
              Save<i className='fa-solid fa-floppy-disk ms-2'></i>
            </Button>
            <Button
              className='shadow ms-3 p-2 rounded '
              style={{
                color: "#d1372d",
                fontWeight: "bold",
                backgroundColor: "white",
                border: "none",
                width: "auto",
              }}
              onClick={() => cancelHandler()}
            >
              Cancel <i className='bi bi-x-lg ms-2'></i>
            </Button>
          </>
        )}
      </Row>
      <Row style={{ marginBottom: "4rem" }}>
        <Form
          id='personalFormId'
          onSubmit={submitPersonalFormHandler}
          noValidate
        >
          {/* *******Personal Information Name******* */}
          <Form.Group>
            <Form.Label
              className='text-muted m-0'
              style={{ fontSize: "0.8rem" }}
            >
              Name
            </Form.Label>
            <Form.Control
              type='text'
              disabled={isEditPersonal}
              placeholder={personalForm ? personalForm.name : ""}
              value={personalForm ? personalForm.name : ""}
              onChange={(e) => setPersonalField("name", e.target.value)}
              isInvalid={!!personalErrors.name}
            />
            <Form.Control.Feedback type='invalid'>
              {personalErrors.name}
            </Form.Control.Feedback>
          </Form.Group>
          {/* *******Personal Information Email******* */}
          <Form.Group>
            <Form.Label
              className='text-muted m-0'
              style={{ fontSize: "0.8rem" }}
            >
              Email
            </Form.Label>
            <Form.Control
              type='email'
              value={personalForm.email ? personalForm.email : ""}
              disabled={isEditPersonal}
              placeholder={personalForm.email ? personalForm.email : ""}
              onChange={(e) => setPersonalField("email", e.target.value)}
              isInvalid={!!personalErrors.email}
            />
            <Form.Control.Feedback type='invalid'>
              {personalErrors.email}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group>
            <Form.Label
              className='text-muted m-0'
              style={{ fontSize: "0.8rem" }}
            >
              Phone
            </Form.Label>
            <NumberFormat
              format='(###) ###-####'
              mask='_'
              isNumericString={true}
              customInput={Form.Control}
              placeholder={personalForm.phone}
              disabled={isEditPersonal}
              onValueChange={(e) => setPersonalField("phone", e.floatValue)}
              isInvalid={!!personalErrors.phonenumber}
            />
            <Form.Control.Feedback type='invalid'>
              {personalErrors.phonenumber}
            </Form.Control.Feedback>
          </Form.Group>
        </Form>
      </Row>
      {/* Edit Business Information */}
      <Row className='my-4'>
        <Col>
          <strong>Business Info</strong>
        </Col>
        {isEditBusiness ? (
          <Button
            type='submit'
            className='shadow p-2 rounded text-end'
            style={{
              color: "black",
              backgroundColor: "white",
              border: "none",
              width: "auto",
            }}
            onClick={() => setIsEditBusiness(false)}
          >
            Edit<i className='bi bi-pencil-fill ms-2'></i>
          </Button>
        ) : (
          <>
            <Button
              type='submit'
              className='shadow p-2 rounded'
              style={{
                color: "#3b8543",
                fontWeight: "bold",
                backgroundColor: "white",
                border: "none",
                width: "auto",
              }}
              form='editBusinessForm'
            >
              Save<i className='fa-solid fa-floppy-disk ms-2'></i>
            </Button>
            <Button
              className='shadow ms-3 p-2 rounded '
              style={{
                color: "#d1372d",
                fontWeight: "bold",
                backgroundColor: "white",
                border: "none",
                width: "auto",
              }}
              onClick={() => cancelBusinessHandler()}
            >
              Cancel <i className='bi bi-x-lg ms-2'></i>
            </Button>
          </>
        )}
      </Row>

      <Row style={{ marginBottom: "4rem" }}>
        <Form
          id='editBusinessForm'
          onSubmit={submitBusinessFormHandler}
          noValidate
        >
          {/* *******Business name******* */}
          <Form.Group>
            <Form.Label
              className='text-muted m-0'
              style={{ fontSize: "0.8rem" }}
            >
              Business Name
            </Form.Label>
            <Form.Control
              type='text'
              disabled={isEditBusiness}
              placeholder={businessForm ? businessForm.businessName : ""}
              value={businessForm ? businessForm.businessName : ""}
              onChange={(e) =>
                setBusinessFieldB("businessName", e.target.value)
              }
              isInvalid={!!businessErrors.businessName}
            />
            <Form.Control.Feedback type='invalid'>
              {businessErrors.businessName}
            </Form.Control.Feedback>
          </Form.Group>
          {/* *******Business description******* */}
          <Form.Group>
            <Form.Label
              className='text-muted m-0'
              style={{ fontSize: "0.8rem" }}
            >
              Details
            </Form.Label>
            <Form.Control
              as='textarea'
              rows={3}
              type='text'
              disabled={isEditBusiness}
              placeholder={businessForm ? businessForm.details : ""}
              value={businessForm ? businessForm.details : ""}
              onChange={(e) => setBusinessFieldB("details", e.target.value)}
              isInvalid={!!businessErrors.details}
            />
            <Form.Control.Feedback type='invalid'>
              {businessErrors.details}
            </Form.Control.Feedback>
          </Form.Group>
          {/* *******Years of Experience******* */}
          <Form.Group>
            <Form.Label
              className='text-muted m-0'
              style={{ fontSize: "0.8rem" }}
            >
              Years of Experience
            </Form.Label>
            <Form.Control
              type='text'
              disabled={isEditBusiness}
              placeholder={businessForm ? businessForm.experience : ""}
              value={businessForm ? businessForm.experience : ""}
              onChange={(e) => setBusinessFieldB("experience", e.target.value)}
              isInvalid={!!businessErrors.experience}
            />
            <Form.Control.Feedback type='invalid'>
              {businessErrors.experience}
            </Form.Control.Feedback>
          </Form.Group>
        </Form>
      </Row>
      {/* *******Download document******* */}
      <Row className='mb-5'>
        <strong>Business documents</strong>
        {docInfoIns.length === 0 && docInfoLic.length === 0 && (
          <p className='text-muted'>No files have been uploaded</p>
        )}
        {docInfoIns.length !== 0 && (
          <DocumentsTable
            arr={docInfoIns}
            name='Insurance'
            uplFun={uplFun}
            delFun={delFun}
          />
        )}
        {docInfoLic.length !== 0 && (
          <DocumentsTable
            arr={docInfoLic}
            name='License'
            uplFun={uplFun}
            delFun={delFun}
          />
        )}
        {chooseAlert.text !== undefined ? (
          <Alert variant={chooseAlert.variant}>{chooseAlert.text}</Alert>
        ) : (
          ""
        )}
        <Form onSubmit={handleSubmitDocument}>
          <Button
            type='button'
            className={
              !documentUpload ? "shadow p-2 rounded " : "visually-hidden"
            }
            onClick={() => setDocumentUpload(true)}
            style={{
              color: "#415da5",
              fontWeight: "bold",
              backgroundColor: "white",
              border: "none",
            }}
          >
            Add Document<i className='bi bi-plus-lg ms-2'></i>
          </Button>

          {documentUpload && (
            <Row>
              <Col lg={5} md={6} sm={12}>
                <Form.Group
                  controlId='formFile'
                  className='me-2 shadow rounded w-100'
                >
                  <Form.Control
                    type='file'
                    onChange={(e) => setDocuments(e.target.files)}
                  />
                </Form.Group>
              </Col>
              <Col lg={3} md={2} sm={12}>
                <InputGroup className='justify-content-end '>
                  <Dropdown>
                    <Dropdown.Toggle
                      variant='outline-secondary'
                      id='input-group-dropdown-1'
                    >
                      {typeDocument}
                    </Dropdown.Toggle>
                    <Dropdown.Menu className='bg-white'>
                      <Dropdown.Item
                        className='bg-white text-black'
                        onClick={() => setTypeDocument("Insurance")}
                      >
                        Insurance
                      </Dropdown.Item>
                      <Dropdown.Item
                        className='bg-white text-black'
                        onClick={() => setTypeDocument("License")}
                      >
                        License
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </InputGroup>
              </Col>
              <Col lg={4} md={4} sm={12}>
                <Row>
                  <Col>
                    <Button
                      type='submit'
                      className='shadow p-2 rounded w-100'
                      style={{
                        color: "#3b8543",
                        fontWeight: "bold",
                        backgroundColor: "white",
                        border: "none",
                      }}
                    >
                      Save<i className='fa-solid fa-floppy-disk ms-2'></i>
                    </Button>
                  </Col>
                  <Col>
                    <Button
                      className='shadow ms-3 p-2 rounded w-100'
                      style={{
                        color: "#d1372d",
                        fontWeight: "bold",
                        backgroundColor: "white",
                        border: "none",
                      }}
                      onClick={() => {
                        setDocumentUpload(false)
                        setTypeDocument("Type")
                      }}
                    >
                      Cancel <i className='bi bi-x-lg ms-2'></i>
                    </Button>
                  </Col>
                </Row>
              </Col>
            </Row>
          )}
        </Form>
      </Row>
    </Stack>
  )
}

export default BusinessManager
