import React from "react"
import { Row, Col, Stack } from "react-bootstrap"
import Persona from "../Persona"
import Raiting from "../Raiting"

const BusinessPreviewTop = ({ avatar, name, location, locationId, rating }) => {
  return (
    <Row className='d-flex justify-content-center justify-content-xl-start w-100 m-0 p-0'>
      <Col lg={7} md={10} sm={12} className='mt-5'>
        <Row className='w-100 m-0 p-0'>
          <Col
            lg={2}
            md={2}
            sm={12}
            className='d-flex justify-content-center mb-2'
          >
            <Persona img={avatar !== undefined && avatar} />
          </Col>
          <Col lg={10} md={10} sm={12}>
            <Row className='d-flex justify-content-start'>
              <Col xs={12} md={12} className='resp-text-title mb-2'>
                <h4 className='fw-bold text-black'>
                  {name !== undefined && name}
                </h4>
              </Col>
              <Col xs={12} className='text-center mb-2'>
                <Stack
                  direction='horizontal'
                  className='d-flex align-items-center'
                >
                  <i className='fa-solid fa-location-dot text-orange me-2'></i>
                  <h6 className='fw-normal text-black resp-par-text m-0'>
                    {location.length > 0 && location[locationId].address}
                  </h6>
                </Stack>
              </Col>
              <Col xs={12}>
                <Row className='resp-rating'>
                  <Col xs={6}>
                    <Raiting
                      value={rating !== undefined ? rating.rating : 0}
                      text={
                        rating !== undefined ? `(${rating.ratingNumber})` : ""
                      }
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
      </Col>
    </Row>
  )
}

export default BusinessPreviewTop
