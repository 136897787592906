import React from 'react'

let lunch = {
  start: { hours: '1', minutes: '00', td: 'AM' },
  end: { hours: '1', minutes: '00', td: 'AM' },
  isLunch: true,
}

const addStartHour = (hour) => {
  lunch.start.hours = hour
}
const addStartMinutes = (min) => {
  lunch.start.minutes = min
}
const addStartTd = (td) => {
  lunch.start.td = td
}
const addEndHour = (hour) => {
  lunch.end.hours = hour
}
const addEndMinutes = (min) => {
  lunch.end.minutes = min
}
const addEndTd = (td) => {
  lunch.end.td = td
}
const addIsLunch = (vol) => {
  if (vol === false) {
    lunch = {
      start: { hours: '0', minutes: '00', td: 'AM' },
      end: { hours: '0', minutes: '00', td: 'AM' },
      isLunch: false,
    }
  } else {
    lunch.isLunch = vol
  }
}

const getLunch = () => {
  return lunch
}
/**
 * Check lunch time
 */
const checkLunch = () => {
  const startMin =
    lunch.start.minutes === undefined ? 0 : Number(lunch.start.minutes)
  const endMin = lunch.end.minutes === undefined ? 0 : Number(lunch.end.minutes)
  const sh =
    lunch.start.td === 'PM'
      ? lunch.start.hours == 12
        ? Number(lunch.start.hours)
        : 12 + Number(lunch.start.hours)
      : Number(lunch.start.hours)
  const sm = startMin === 0 ? 0 : startMin / 60
  const st = sh + sm
  const eh =
    lunch.end.td === 'PM'
      ? 12 + Number(lunch.end.hours)
      : Number(lunch.end.hours)
  const em = endMin === 0 ? 0 : endMin / 60
  const et = eh + em

  return st <= et
}

const setLunch = (oldLunch) => {
  if (oldLunch.start.hours === '0') {
    lunch = {
      start: { hours: '0', minutes: '00', td: 'AM' },
      end: { hours: '0', minutes: '00', td: 'AM' },
      isLunch: false,
    }
  } else {
    lunch.start = oldLunch.start
    lunch.end = oldLunch.end
    lunch.isLunch = true
  }
}

export {
  addStartHour,
  addStartMinutes,
  addStartTd,
  addEndHour,
  addEndMinutes,
  addEndTd,
  addIsLunch,
  getLunch,
  setLunch,
  checkLunch,
}
