import React, { useState, useEffect } from "react";
import { MainContext } from "../adminDashboard";
import { useSelector, useDispatch } from "react-redux";
import { uploadDocument, deleteDocument, getBusinessById } from "../../actions";
import { openLinkInNewTab } from "../../utils/openLinkInNewTab";

const AdminModal = ({
  settingsInfo,
  objectM,
  setShowMain,
  setShowPreview,
  showMain,
  showPreview,
  name,
  id,
  deleteMembers,
  approveHandler,
  googleKey,
}) => {
  const [item, setItem] = useState();
  const [switchFilter, setSwitchFilter] = useState(false);
  const dispatch = useDispatch();
  const { upload } = useSelector((state) => state.uploadDocument);
  const { success } = useSelector((state) => state.deleteDocument);

  useEffect(() => {
    const showO = objectM.find((x) => x._id === id);
    setItem(() => showO);
  }, []);

  const deleteClickHandler = () => {
    setSwitchFilter(true);
    setShowMain(false);
    deleteMembers(item.role, item._id);
  };
  const approvedHandler = () => {
    approveHandler(item._id);
    setShowMain(false);
  };
  const updateDocumentHandler = (file, oldFile, type, id) => {
    dispatch(uploadDocument(file));
    dispatch(deleteDocument(oldFile, id));
    dispatch(getBusinessById(item._id));
  };
  const deleteDocumentHandler = (file, id) => {
    dispatch(deleteDocument(file, id));
    dispatch(getBusinessById(item._id));
  };

  const onPreview = () => {
    const stateData = {
      id: item._id,
      category: item.category,
    };
    openLinkInNewTab("/preview-business", stateData);
  };

  if (!item) {
    return null;
  }
  return (
    <>
      {!showPreview && (
        <MainContext
          settingsInfo={settingsInfo}
          item={item}
          showMain={showMain}
          setShowMain={setShowMain}
          showPreview={showPreview}
          setShowPreview={setShowPreview}
          deleteClickHandler={deleteClickHandler}
          approvedHandler={approvedHandler}
          updateDocumentHandler={updateDocumentHandler}
          deleteDocumentHandler={deleteDocumentHandler}
          onPreview={onPreview}
        />
      )}
    </>
  );
};

export default AdminModal;
