import store from "../../../../store"
import { leftRating } from "../../../../actions"

export default class RatingDoo {
  constructor(rat) {
    this.rating = rat
    this.dispatch = store
  }
  getRating() {
    return this.rating
  }
  updateRating(rating, userId) {
    store.dispatch(leftRating(rating, userId))
    this.rating = rating
  }
}
