import React from "react"
import { LinkContainer } from "react-router-bootstrap"

const BlackButton = ({ link, text }) => {
  return (
    <LinkContainer to={link}>
      <button type='button' className='black-button'>
        {text}
      </button>
    </LinkContainer>
  )
}

export default BlackButton
