import { useState } from "react"
import { ButtonShadow } from "../../components"
import { Modal, Form } from "react-bootstrap"
import FileViewer from "react-file-viewer"
import { Viewer, Worker } from "@react-pdf-viewer/core"
import { zoomPlugin } from "@react-pdf-viewer/zoom"
import "@react-pdf-viewer/core/lib/styles/index.css"
import "@react-pdf-viewer/zoom/lib/styles/index.css"
import { Document, Page } from "react-pdf"

const DocumentsTable = ({ arr, name }) => {
  const [numPages, setNumPages] = useState(null)
  const [pageNumber, setPageNumber] = useState(1)
  const [showModalPreview, setShowModalPreview] = useState(false)
  const [fileType, setFileType] = useState("")
  const [filePath, setFilePath] = useState("")

  /** Preview Handle */
  const previewHandle = (item) => {
    const tp = item.name.split(".").pop()
    const pth = item.path
    setFileType(tp.toString())
    setFilePath(pth)
    setShowModalPreview(true)
  }
  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages)
  }

  const zoomPluginInstance = zoomPlugin()
  const { ZoomIn, ZoomOut } = zoomPluginInstance
  /** Toast for preview document */
  const toastPreview = () => {
    return (
      <Modal
        show={showModalPreview}
        onHide={() => setShowModalPreview(false)}
        dialogClassName='modal-dialog modal-xl'
        aria-labelledby='example-custom-modal-styling-title'
      >
        <Modal.Header closeButton className='w-100'>
          <Modal.Title>Uploaded File</Modal.Title>
        </Modal.Header>
        <Modal.Body className='w-100'>
          {fileType === "pdf" || fileType === "PDF" ? (
            <div>
              <div className='d-flex justify-content-center w-100'>
                <ZoomIn />
                <ZoomOut />
              </div>
              <Worker workerUrl='https://unpkg.com/pdfjs-dist@2.16.105/build/pdf.worker.min.js'>
                <Viewer fileUrl={filePath} plugins={[zoomPluginInstance]} />
              </Worker>
            </div>
          ) : (
            // <div style={{ height: "100%" }}>
            <FileViewer fileType={fileType} filePath={filePath} />
            // </div>
          )}
        </Modal.Body>
        <Modal.Footer className='w-100'>
          <ButtonShadow
            bgcolor='white'
            color='#d1372d'
            text='Close'
            handleOnClick={() => setShowModalPreview(false)}
          />
        </Modal.Footer>
      </Modal>
    )
  }

  return (
    <>
      {showModalPreview ? toastPreview() : ""}

      <table className='table table-sm caption-top mt-3'>
        <tbody>
          {arr.map((x, index) => (
            <tr key={index}>
              <td className='w-100'>
                {name === "Insurance"
                  ? "Business Insurance"
                  : "Business License"}
              </td>
              <td className='text-end '>
                <ButtonShadow
                  icon='bi bi-eye-fill'
                  color='#282828'
                  handleOnClick={() => previewHandle(x)}
                />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </>
  )
}

export default DocumentsTable
