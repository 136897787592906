import React from "react";
import { Pagination, Container } from "react-bootstrap";

const PaginationCustom = ({
  totalPages,
  setPage,
  currentPage,
  onPrev,
  onNext,
}) => {
  return (
    <Container>
      <div className="d-flex justify-content-center">
        <Pagination
          onChange={(e) => {
            console.log(e);
          }}
        >
          <Pagination.Prev onClick={onPrev} />
          {Array(totalPages)
            .fill(1)
            .map((item, i) => (
              <Pagination.Item
                key={i + 1}
                active={currentPage === i + 1}
                onClick={() => setPage(i + 1)}
              >
                {i + 1}
              </Pagination.Item>
            ))}
          <Pagination.Next onClick={onNext} />
        </Pagination>
      </div>
    </Container>
  );
};

export default PaginationCustom;
