import React, { useEffect, useState } from "react"
import { Row, Col, Container } from "react-bootstrap"
import { useDispatch, useSelector } from "react-redux"
import { sendEmail } from "../actions"
import { useNavigate } from "react-router-dom"
import { ContactUsTop, ContactUsContent } from "../components/contactUsScreen"
import { toast } from "react-toastify"

const ContactUsScreen = () => {
  const dispatch = useDispatch()
  const history = useNavigate()
  //state for form
  const [form, setForm] = useState({
    name: "",
    phnub: null,
    email: "",
    message: "",
    to: "contact@doomoble.com",
    subject: `New message from contact us.`,
  })
  const [errors, setErrors] = useState({})
  //state for message if success
  const [message, setMessage] = useState("")
  //fill form and error
  const setField = (field, value) => {
    setForm({
      ...form,
      [field]: value,
    })

    if (!!errors[field])
      setErrors({
        ...errors,
        [field]: null,
      })
  }
  const { emailStatus } = useSelector((state) => state.sendEmail)

  useEffect(() => {
    if (emailStatus !== undefined) {
      setMessage("")
    }
  }, [emailStatus, message])

  //validate form function
  const validateForm = () => {
    const { name, phnub, email, message } = form
    const newErrors = {}
    //check name
    if (name === "") {
      newErrors.name = "Please enter name"
    }
    //check phone number
    if (phnub === null) {
      newErrors.phnub = "Please enter phone number"
    } else if (phnub.length < 9) {
      newErrors.phnub = "Please enter correct phone number"
    }
    //check email
    if (email === "") {
      newErrors.email = "Please enter email"
    }
    //check message
    if (message === "") {
      newErrors.message = "Please enter message"
    }

    return newErrors
  }
  //function for submit form
  const submitHandler = (e) => {
    e.preventDefault()

    const formErrors = validateForm()
    if (Object.keys(formErrors).length > 0) {
      setErrors(formErrors)
    } else {
      const messages = `Customer name: ${form.name}<br> phone:${form.phnub}<br> email: ${form.email}<br> message:${form.message}`
      form.message = messages
      dispatch(sendEmail(form))
      toast.success(`Thank ${form.name} for contacting us!`)

      setForm({
        name: "",
        phnub: null,
        email: "",
        message: "",
        to: "contact@doomoble.com",
        subject: `New message from contact us.`,
      })

      //SEND EMAIL
    }
  }

  return (
    <Container fluid className='m-0 p-0'>
      <Row className='m-0 justify-content-md-center p-0'>
        <Col className='m-0 p-0'>
          <ContactUsTop />
          <ContactUsContent
            submitHandler={submitHandler}
            form={form}
            errors={errors}
            setField={setField}
          />
        </Col>
      </Row>
    </Container>
  )
}

export default ContactUsScreen
