import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Container, Row, Col } from "react-bootstrap"
import { useNavigate } from "react-router-dom"
import ButtonShadow from "../UI/ButtonShadow"
import { publishPage } from "../../actions/businessActions"
import { toast } from "react-toastify"

const PageHeader = () => {
  const [approved, setApproved] = useState(false)
  const [published, setPublished] = useState(false)
  const [disableButton, setDisableButton] = useState({
    left: true,
    right: false,
  })
  const [idLocation, setIdLocation] = useState(0)
  const [show, setShow] = useState(false)
  const [location, setLocation] = useState([])

  const history = useNavigate()
  const dispatch = useDispatch()
  const { businessInfo } = useSelector((state) => state.businessLogin)

  useEffect(() => {
    if (businessInfo) {
      if (businessInfo !== undefined) {
        setApproved(businessInfo.approved)
        setPublished(businessInfo.published)
        //GET PUBLISH INFORMATION
        setLocation(businessInfo.locations.map((s) => s))
        if (businessInfo.locations.length < 2) {
          setDisableButton(() => ({ left: true, right: true }))
        }
      }
    }
  }, [businessInfo])

  const clickPreviewHandler = () => {
    history(`/preview/${businessInfo.category}/${businessInfo._id}`)
  }

  // handler address change
  const handlAddr = (p) => {
    if (p === "right") {
      setIdLocation(idLocation + 1)
      if (idLocation + 1 === businessInfo.locations.length - 1) {
        setDisableButton({ left: false, right: true })
      }
    } else {
      setIdLocation(idLocation - 1)
      if (idLocation - 1 === 0) {
        setDisableButton({ left: true, right: false })
      }
    }
  }
  const { loading, error, publishInfo } = useSelector(
    (state) => state.publishPage
  )
  useEffect(() => {
    // if (publishInfo) {
    //   if (published) {
    //     toast.warning("Your page has been unpublished.")
    //   } else {
    //     toast.success("Your page has been published.")
    //   }
    //   setPublished(!published)
    // }
    if (error) {
      toast.error(error)
    }
  }, [error])
  // }, [publishInfo, error])

  const changePublishHandler = () => {
    if (approved) {
      if (published) {
        dispatch(publishPage("false", businessInfo._id))
        toast.warning("Your page has been unpublished.")
      } else {
        dispatch(publishPage("true", businessInfo._id))
        toast.success("Your page has been published.")
      }
    } else {
      toast.error("Your business hasn't been approved yet.")
    }
  }

  return (
    <Container>
      <Row>
        <Col xl={2} md={2} xs={12}>
          <h6 className='text-black my-3'>
            <strong>{`Welcome, ${businessInfo && businessInfo.name}`}</strong>
          </h6>
        </Col>
        <Col
          xl={2}
          md={2}
          xs={12}
          className='col-4 d-flex flex-row border  border-1 rounded w-auto p-3 my-2'
          as='h6'
          style={{ color: "black" }}
        >
          {approved ? (
            <>
              <i
                className='bi bi-check-circle-fill mx-3'
                style={{ color: "green" }}
              ></i>
              <strong> Approved</strong>
            </>
          ) : (
            <>
              <i
                className='bi bi-exclamation-circle-fill mx-3'
                style={{ color: "red" }}
              ></i>
              <strong> Not Approved</strong>
            </>
          )}
        </Col>

        <Col xl={2} md={2} xs={5} className='my-auto'>
          <div className='form-check form-switch my-auto'>
            <input
              className='form-check-input border rounded p-2 mt-3'
              type='checkbox'
              role='switch'
              id='publishCheck'
              checked={published}
              onChange={changePublishHandler}
              // onChange={() => {
              //   !approved ? setShow(true) : setShow(false)
              // }}
            />
            <p className='text-black'>Click to publish your page</p>
          </div>
        </Col>
        <Col xl={3} md={3} xs={12} className='my-4 h-100'>
          <ButtonShadow
            text='Click to preview page'
            color='#1976D2'
            icon='bi bi-eye-fill mx-3'
            handleOnClick={clickPreviewHandler}
          />
        </Col>
        <Col xl={3} md={3} xs={12}>
          <Row className='my-3'>
            <Col
              as='button'
              lg={1}
              md={1}
              sm={1}
              disabled={disableButton.left}
              className='text-center align-middle border-0 bg-white'
              onClick={() => handlAddr("left")}
            >
              <i className='fa-solid fa-angle-left'></i>
            </Col>
            <Col lg={10} md={10} sm={7}>
              <strong>
                {location.length > 0 && location[idLocation].address}
              </strong>
            </Col>
            <Col
              as='button'
              lg={1}
              md={1}
              sm={1}
              className='text-center align-middle border-0 bg-white'
              disabled={disableButton.right}
              onClick={() => handlAddr("right")}
            >
              <i className='fa-solid fa-angle-right'></i>
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  )
}

export default PageHeader
