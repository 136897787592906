import React from "react";
import { Container, Col, Row } from "react-bootstrap";
import { Service, ServiceList } from "../../components";

const ShowCategory = ({ listView, mainServices, titleDM }) => {
  const sortByDate = () => {
    if (mainServices !== undefined) {
      const tMS = mainServices.sort(
        (x, y) => new Date(y.joined) - new Date(x.joined)
      );
      return returnedArray(tMS);
    }
  };
  const sortByRating = () => {
    console.log("by rating");
    if (mainServices !== undefined) {
      const tMS = mainServices.sort((x, y) => y.rating - x.rating);
      return returnedArray(tMS);
    }
  };

  const returnedArray = (arr) => {
    return arr.length > 0 ? (
      !listView ? (
        <Row>
          {arr.map((e, index) => (
            <Col key={index} sm={12} md={6} lg={3} xlg={3}>
              <Service service={e} />
            </Col>
          ))}
        </Row>
      ) : (
        <Row>
          <Col sm={12} md={12} lg={6} xlg={6}>
            {arr.map((e, index) => (
              <Col key={index} sm={12} md={12} lg={12} xlg={12}>
                <ServiceList service={e} />
              </Col>
            ))}
          </Col>

          <Col sm={12} md={12} lg={6} xlg={6}></Col>
        </Row>
      )
    ) : (
      ""
    );
  };

  return (
    <Container>
      <Row>
        <Col sm={12} md={12} lg={12} xlg={12}>
          {titleDM === "Recently Added" ? sortByDate() : sortByRating()}
        </Col>
      </Row>
    </Container>
  );
};

export default ShowCategory;
