import { useEffect, useState } from "react"
import {
  Stack,
  Container,
  Form,
  FormGroup,
  Button,
  Row,
  Toast,
  ToastContainer,
} from "react-bootstrap"
import { useDispatch, useSelector } from "react-redux"
import { findUserByEmail, sendEmail } from "../actions"
import { useNavigate } from "react-router-dom"
import { toast } from "react-toastify"
import { passResetMessage } from "../assets/email/passwordReset"

const ForgotScreen = ({ type }) => {
  const [email, setEmail] = useState()
  const [error, setError] = useState()
  const history = useNavigate()

  const { getUserBEMAILInfo } = useSelector((state) => state.findUserByEmail)

  useEffect(() => {
    if (getUserBEMAILInfo !== undefined) {
      if (getUserBEMAILInfo.id !== 0) {
        const messageText = passResetMessage(getUserBEMAILInfo.id)
        dispatch(
          sendEmail({
            name: getUserBEMAILInfo.name,
            email: email,
            message: messageText,
            to: email,
            subject: `DooMoble`,
          })
        )
        toast.success(
          "Check your email. We've sent an email with link to reset your password."
        )
        // history(`/signin/${type}`)
        setEmail("")
      } else {
        toast.error("Sorry account with this email not found.")
      }
    }
  }, [getUserBEMAILInfo])

  const dispatch = useDispatch()

  const submitHandler = (e) => {
    e.preventDefault()
    if (email === undefined) {
      setError(true)
      toast.error("Please enter email")
      return
    } else {
      setError(false)
      dispatch(findUserByEmail(email, type))
    }
  }

  return (
    <Stack direction='vertical' className='bg-white text-muted'>
      <Container>
        <Row className='m-0 '>
          <Form
            className='my-5 ls-form-main mx-auto'
            onSubmit={submitHandler}
            noValidate
          >
            <div className='text-center'>
              <h6 className='text-black text-capitalize mt-5'>
                Forgot your password
              </h6>
            </div>
            <FormGroup>
              <Form.Label as='p' style={{ fontSize: "0.8rem" }}>
                Email
              </Form.Label>
              <Form.Control
                type='email'
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                isInvalid={error}
                placeholder=''
              />
            </FormGroup>

            <div className='text-end mt-4'>
              <Button type='submit' variant='dark'>
                Reset Password
              </Button>
            </div>
          </Form>
        </Row>
      </Container>
    </Stack>
  )
}

export default ForgotScreen
