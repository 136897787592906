import React from "react"
import { Col, Row } from "react-bootstrap"
import { ContentOrange, ContentWhite } from "./index"

const ContactUsContent = ({ submitHandler, form, errors, setField }) => {
  return (
    <Row className='d-flex justify-content-center m-0 p-0'>
      <Col xl={6} md={6} xs={12} className='m-0 p-0'>
        <ContentOrange />
      </Col>
      <Col xl={6} md={6} xs={12} className='m-0 p-0'>
        <ContentWhite
          submitHandler={submitHandler}
          form={form}
          errors={errors}
          setField={setField}
        />
      </Col>
    </Row>
  )
}

export default ContactUsContent
